import app_config from '@/app/constants';
import { isDev } from '@/shared';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: app_config.SENTRY_DSN,
  enabled: !isDev,
  environment: app_config.ENVIROPMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  replaysOnErrorSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  tracesSampleRate: 1,
});
