import { createUploadLink } from 'apollo-upload-client';

import { isDev } from '@/shared';
import { ApolloClient, ApolloLink, from } from '@apollo/client';
import app_config from '@app/constants';

import { authLink, globalErrorLink, refreshTokenErrorLink } from './auth';
import { cache } from './cache';

const httpLink: ApolloLink | any = createUploadLink({
  uri: (isDev ? '' : app_config.API_URL) + '/graphql/',
});

export const link = from([
  globalErrorLink,
  refreshTokenErrorLink,
  authLink,
  httpLink,
]);
export const apolloClient = new ApolloClient({
  cache,
  link,
});
