import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const MobileLogoIcon: FC<IconProps> = ({
  height = 32,
  width = 57,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 57 32"
    fill="none"
    {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0021 6.91553V16.8961C15.6786 16.4751 13.5492 15.9227 11.0342 15.9227C8.51911 15.9227 6.33173 16.401 5.11886 16.8863V6.91553H0V32.0001H5.11886V21.5243C6.79253 21.0466 9.28768 20.7684 11.0342 20.7684C12.7955 20.7684 15.3159 21.0516 17.0021 21.5342V32.0001H22.1235V6.91553H17.0021Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.8889 15.9986C56.8889 5.31395 50.304 0 41.3255 0H0V4.84097H40.0725C47.2215 4.84097 51.778 8.33121 51.778 15.9986C51.778 23.6609 47.2215 27.1559 40.0725 27.1559H29.1288C31.3663 24.243 36.1247 22.7104 38.8343 20.1788C40.4295 18.6885 41.7124 16.6944 41.7124 13.982C41.7124 11.3558 40.487 9.32714 38.7021 8.02385C37.0022 6.78202 35.3673 6.25823 33.4254 6.25823C28.7002 6.25823 23.7178 9.65163 23.7178 16.0033H28.8199C28.8199 12.8435 31.079 11.097 33.4254 11.097C34.9966 11.097 36.6106 12.3177 36.6106 13.9845C36.6106 16.3388 33.5883 18.1807 31.7978 19.2272C27.8246 21.5758 23.7804 24.4232 23.7804 29.4863L23.7329 31.9969H40.07C50.4289 31.9969 56.8889 26.6829 56.8889 15.9986Z"
      fill="#FF457C"
    />
  </svg>
);
