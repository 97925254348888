import { FC } from 'react';
import cx from 'classnames';

import { CheckRoundDenseIcon, Typography } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface InputStep {
  label: string;
  active: boolean;
}
export interface InputStepsProps {
  items: InputStep[];
}
export const InputSteps: FC<InputStepsProps> = ({ items }) => (
  <ul className={styles.inputSteps}>
    {items.map((el, idx) => (
      <Typography
        key={idx}
        tag="li"
        variant="body-14"
        className={cx(
          styles.inputSteps__item,
          el.active && styles.inputSteps__item_active,
        )}>
        <CheckRoundDenseIcon />
        {el.label}
      </Typography>
    ))}
  </ul>
);
