import { KeyboardEvent } from 'react';

export interface INumberFilterParams {
  allowNegative?: boolean;
  allowDecimal?: boolean;
  decimalPlaces?: number;
}

export const handleNumberInput =
  ({ allowDecimal, allowNegative, decimalPlaces }: INumberFilterParams = {}) =>
  (event: KeyboardEvent<HTMLInputElement>) => {
    const { currentTarget, key } = event;
    const value = currentTarget.value;
    const selectionStart = currentTarget.selectionStart!;
    const selectionEnd = currentTarget.selectionEnd;

    // Check if the key is a digit
    const isDigit = /\d/.test(key);
    // Check if the key is a minus sign (if allowNegative is true)
    const isMinusSign = allowNegative && key === '-' && selectionStart === 0;
    // Check if the key is a decimal point (if allowDecimal is true)
    const isDecimalPoint = allowDecimal && key === '.';

    if (isMinusSign) {
      // Allow minus sign only if it's not already present
      if (value.includes('-')) {
        event.preventDefault();
      }

      return;
    }

    if (isDecimalPoint) {
      // Allow decimal point only if it's not already present
      if (value.includes('.')) {
        event.preventDefault();
      }

      return;
    }

    if (isDigit) {
      // Check for decimal places limit
      if (allowDecimal && decimalPlaces !== undefined) {
        const decimalIndex = value.indexOf('.');

        if (decimalIndex !== -1 && selectionStart > decimalIndex) {
          const afterDecimalLength = value.length - decimalIndex - 1;

          if (
            afterDecimalLength >= decimalPlaces &&
            selectionStart === selectionEnd
          ) {
            event.preventDefault();
          }
        }
      }
    } else {
      event.preventDefault();
    }
  };
