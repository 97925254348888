import { FC, useState } from 'react';

import {
  PropsWithNativeTypes,
  SearchIcon,
  TextField,
  TextFieldProps,
} from '@letsdance/ui-kit';

export const SearchTextField: FC<
  PropsWithNativeTypes<TextFieldProps, HTMLInputElement>
> = ({ initValue, name, onChange, onClear, placeholder, style, ...rest }) => {
  const [value, setValue] = useState(initValue);

  return (
    <TextField
      id={name}
      name={name}
      placeholder={placeholder}
      prependSlot={<SearchIcon />}
      initValue={initValue}
      onChange={(e) => {
        setValue(e.target.value);
        onChange && onChange(e);
      }}
      clearable={!!value}
      onClear={() => {
        setValue(initValue);
        onClear && onClear();
      }}
      fullWidth
      style={{ minWidth: 400, ...style }}
      {...rest}
    />
  );
};
