import { useEffect } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  CreateCourseMutation,
  CreateCourseMutationVariables,
  Errors,
  useCreateCourseMutation,
} from '@/apolloGenerated';
import { ApolloErrorService, useTeacherId } from '@/shared';
import { MutationResult } from '@apollo/client';
import { RouterName } from '@app/router';
import { getCourseInput } from '@entities/Course/libs/hooks/getCourseInput';
import { toaster } from '@letsdance/ui-kit';

export const useCreateCourse = (): [
  (
    vars: Pick<CreateCourseMutationVariables, 'input' | 'publish'>,
  ) => Promise<void>,
  MutationResult<CreateCourseMutation>,
] => {
  const { navigate } = useNavigate();
  const idTeacher = useTeacherId()!;
  const [createCourse, result] = useCreateCourseMutation();

  const onCreateCourse = async ({
    input,
    publish,
  }: Pick<CreateCourseMutationVariables, 'input' | 'publish'>) => {
    createCourse({
      variables: {
        idTeacher,
        input: getCourseInput(input),
        publish,
      },
    });
  };

  useEffect(() => {
    if (result.data) {
      const { client, data } = result;

      client.cache.evict({ fieldName: 'productsList' });
      client.cache.evict({ fieldName: 'productsCount' });
      navigate(RouterName.AdminCourseEdit, {
        uuid: data.createProduct.uuid,
      });
      toaster.success({ title: 'Событие успешно создано' });
    }
    if (
      result.error &&
      ApolloErrorService.getFirstError(result.error?.graphQLErrors)?.message ===
        Errors.CanNotPublishProductWithoutTariff
    ) {
      toaster.error({
        title: 'Для публикации необходимо добавить хотя бы один тариф',
      });
    }
  }, [result.data, result.error]);

  return [onCreateCourse, result];
};
