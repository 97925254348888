import React, { FC, useEffect, useState } from 'react';

import { TariffInput, TariffType, useTariffQuery } from '@/apolloGenerated';
import { ActionButtons, ApolloErrorService, Container } from '@/shared';
import { TariffForm, useUpdateTariff } from '@entities/Tariff';
import { LoaderOverlay, Spacer, toaster } from '@letsdance/ui-kit';
import { Head } from '@widgets/Layout';

export interface TariffEditProps {
  uuid: string;
  disabled?: boolean;
  onClose(): void;
  onSuccess(val: TariffType): void;
}
export const TariffEdit: FC<TariffEditProps> = ({
  disabled,
  onClose,
  onSuccess,
  uuid,
}) => {
  const { data, loading } = useTariffQuery({ variables: { uuid } });
  const [updateTariff, { data: updateData, error, loading: saveLoading }] =
    useUpdateTariff();
  const [tariffData, setTariffData] = useState<TariffInput>();

  const handleSubmit = () => {
    if (!tariffData) {
      return toaster.error({ title: 'Отсутствуют данные для тарифа' });
    }
    updateTariff({
      input: tariffData,
      uuid,
    });
  };

  useEffect(() => {
    if (updateData) {
      onSuccess(updateData.updateTariff);
    }
  }, [updateData]);

  return (
    <div>
      <LoaderOverlay show={loading || saveLoading} />
      <Container>
        <Head title={data?.tariff.name || ''} />
      </Container>
      <ActionButtons
        actions={[
          { handler: onClose, label: 'Отменить' },
          { handler: handleSubmit, label: 'Сохранить изменения' },
        ]}
      />
      <Spacer size={10} />
      <Container>
        {data && (
          <TariffForm
            initValues={data.tariff}
            readonly={disabled}
            onChange={setTariffData}
            errors={
              ApolloErrorService.getFirstError(error?.graphQLErrors)?.extensions
                ?.validationErrors
            }
          />
        )}
      </Container>
    </div>
  );
};
