import { FC } from 'react';

import { Container } from '@/shared';
import { Button, Divider } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface ActionButton {
  label: string;
  handler(): void;
  disabled?: boolean;
}
export interface ActionButtonsProps {
  actions: ActionButton[];
}
export const ActionButtons: FC<ActionButtonsProps> = ({ actions }) => (
  <div className={styles.driverAction}>
    {actions.length > 0 && (
      <Container className={styles.driverAction__actions}>
        {actions.map(({ disabled, handler, label }, index) => (
          <Button
            key={index}
            size="small"
            color="secondary"
            disabled={disabled}
            onClick={handler}>
            {label}
          </Button>
        ))}
      </Container>
    )}
    <Divider className={styles.driverAction__hr} />
  </div>
);
