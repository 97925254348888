import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const Graph: FC<IconProps & { small?: boolean }> = ({
  height = 64,
  small,
  width = 64,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`'0 0 ${width} ${height}`}
    fill="none"
    {...rest}>
    {small ? (
      <>
        <path
          d="M36.64 24.0004C41.84 24.0004 44 22.0004 42.08 15.4404C40.78 11.0204 36.98 7.22036 32.56 5.92036C26 4.00036 24 6.16036 24 11.3604V17.1204C24 22.0004 26 24.0004 30 24.0004H36.64Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.0008 29.3996C38.1408 38.6596 29.2608 45.3796 19.1608 43.7396C11.5808 42.5196 5.48084 36.4196 4.24084 28.8396C2.62084 18.7795 9.30084 9.89953 18.5208 8.01953"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ) : (
      <>
        <path
          d="M48.8533 32.0005C55.7867 32.0005 58.6667 29.3339 56.1067 20.5871C54.3733 14.6938 49.3067 9.62715 43.4133 7.89382C34.6667 5.33382 32 8.21382 32 15.1472V22.8271C32 29.3339 34.6667 32.0005 40 32.0005H48.8533Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M53.3342 39.1995C50.8542 51.5461 39.0142 60.5061 25.5476 58.3195C15.441 56.6928 7.30762 48.5595 5.65429 38.4528C3.49429 25.0394 12.401 13.1994 24.6943 10.6927"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    )}
  </svg>
);
