import { Errors } from '@/apolloGenerated';
import { Observable } from '@apollo/client';
import { ContextSetter, setContext } from '@apollo/client/link/context';
import { ErrorHandler, onError } from '@apollo/client/link/error';
import {
  ApolloErrorService,
  getNewTokenDirect,
  persistUser,
} from '@shared/libs';
import {
  loadUser,
  logoutUser,
  setAuthorizationHeaders,
} from '@shared/libs/utils/auth';

import { history, router, RouterName } from '../router';

const refreshTokenHandler: ErrorHandler = ({
  forward,
  graphQLErrors,
  operation,
}) => {
  if (ApolloErrorService.hasError(graphQLErrors, Errors.InvalidAccessToken)) {
    return new Observable((observer) => {
      getNewTokenDirect()
        .then((refreshResponse) => {
          if (!refreshResponse) {
            throw new Error('Not found refresh token');
          }

          persistUser(refreshResponse);

          operation.setContext({
            headers: setAuthorizationHeaders(
              operation.getContext().headers,
              refreshResponse.access_token,
              refreshResponse.refresh_token,
            ),
          });

          return forward(operation);
        })
        .then(() => {
          const subscriber = {
            complete: observer.complete.bind(observer),
            error: observer.error.bind(observer),
            next: observer.next.bind(observer),
          };

          // Retry last failed request
          forward(operation).subscribe(subscriber);
        })
        .catch((error) => {
          observer.error(error);
          logoutUser();
          history.navigate(router.urlFor(RouterName.Authorization));
        });
    });
  }
  if (
    ApolloErrorService.hasError(graphQLErrors, Errors.UserWithThisTokenNotFound)
  ) {
    logoutUser();
    history.navigate(router.urlFor(RouterName.Authorization));
  }
};

export const refreshTokenErrorLink = onError(refreshTokenHandler);

const authContextSetter: ContextSetter = (_, prevContext) => {
  const user = loadUser();

  return {
    headers: setAuthorizationHeaders(prevContext.headers, user?.access_token),
  };
};

export const authLink = setContext(authContextSetter);

export const globalErrorLink = onError(({ graphQLErrors, networkError }) => {
  ApolloErrorService.debugConsoleErrors({ graphQLErrors, networkError });
});
