import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const Bell: FC<IconProps> = ({ height = 40, width = 40, ...rest }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}>
    <path
      d="M24.0392 5.82031C17.4192 5.82031 12.0392 11.2003 12.0392 17.8203V23.6004C12.0392 24.8204 11.5192 26.6804 10.8992 27.7204L8.5992 31.5404C7.1792 33.9004 8.1592 36.5204 10.7592 37.4004C19.3792 40.2804 28.6792 40.2804 37.2992 37.4004C39.7192 36.6004 40.7792 33.7404 39.4592 31.5404L37.1592 27.7204C36.5592 26.6804 36.0392 24.8204 36.0392 23.6004V17.8203C36.0392 11.2203 30.6392 5.82031 24.0392 5.82031Z"
      stroke="white"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M27.7398 6.39988C27.1198 6.21988 26.4798 6.07988 25.8198 5.99988C23.8998 5.75988 22.0598 5.89988 20.3398 6.39988C20.9198 4.91988 22.3598 3.87988 24.0398 3.87988C25.7198 3.87988 27.1598 4.91988 27.7398 6.39988Z"
      stroke="white"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.039 38.1202C30.039 41.4202 27.339 44.1202 24.039 44.1202C22.399 44.1202 20.879 43.4402 19.7991 42.3602C18.7191 41.2802 18.0391 39.7602 18.0391 38.1202"
      stroke="white"
      strokeWidth="2.5"
      strokeMiterlimit="10"
    />
  </svg>
);
