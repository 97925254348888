export enum RouterName {
  Home = 'home',
  Wiki = 'wiki',
  NotFound = '404',
  Authorization = 'auth',
  AdminCourses = 'admin-courses',
  AdminTeachers = 'admin-teachers',
  AdminTeacherEdit = 'admin-teacher-edit',
  AdminTeacherCreate = 'admin-teacher-create',
  AdminProfile = 'admin-profile',
  AdminContact = 'contact',
  AdminPurchases = 'admin-purchases',
  AdminFinance = 'admin-finances',
  AdminWithdrawCreate = 'admin-withdraw-create',
  AdminContactEdit = 'admin-contact-edit',
  AdminCourseEdit = 'admin-event-edit',
  AdminCourseCreate = 'admin-event-create',
}
