import { useUploadImagesMutation } from '@/apolloGenerated';

export const useImageUploader = () => {
  const [uploadImage] = useUploadImagesMutation();

  return async (val: File) => {
    const { data } = await uploadImage({ variables: { images: [val] } });

    return data?.uploadImages[0].url;
  };
};
