import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const PercentageSquare: FC<IconProps & { small?: boolean }> = ({
  height = 64,
  small,
  width = 64,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`'0 0 ${width} ${height}`}
    fill="none"
    {...rest}>
    {small ? (
      <>
        <path
          d="M18 4H30C40 4 44 8 44 18V30C44 40 40 44 30 44H18C8 44 4 40 4 30V18C4 8 8 4 18 4Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6406 30.54L29.7206 17.46"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.46 20.7402C18.8186 20.7402 19.92 19.639 19.92 18.2803C19.92 16.9217 18.8186 15.8203 17.46 15.8203C16.1014 15.8203 15 16.9217 15 18.2803C15 19.639 16.1014 20.7402 17.46 20.7402Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.5379 32.1798C31.8967 32.1798 32.9981 31.0784 32.9981 29.7198C32.9981 28.3612 31.8967 27.2598 30.5379 27.2598C29.1793 27.2598 28.0781 28.3612 28.0781 29.7198C28.0781 31.0784 29.1793 32.1798 30.5379 32.1798Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ) : (
      <>
        <path
          d="M23.9999 5.33334H39.9999C53.3332 5.33334 58.6666 10.6667 58.6666 24V40C58.6666 53.3333 53.3332 58.6667 39.9999 58.6667H23.9999C10.6666 58.6667 5.33325 53.3333 5.33325 40V24C5.33325 10.6667 10.6666 5.33334 23.9999 5.33334Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.1875 40.72L39.6275 23.2799"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.28 27.6536C25.0915 27.6536 26.56 26.1853 26.56 24.3738C26.56 22.5623 25.0915 21.0938 23.28 21.0938C21.4685 21.0938 20 22.5623 20 24.3738C20 26.1853 21.4685 27.6536 23.28 27.6536Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.7172 42.9064C42.529 42.9064 43.9975 41.4379 43.9975 39.6264C43.9975 37.8149 42.529 36.3464 40.7172 36.3464C38.9058 36.3464 37.4375 37.8149 37.4375 39.6264C37.4375 41.4379 38.9058 42.9064 40.7172 42.9064Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    )}
  </svg>
);
