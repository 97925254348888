import { gql } from '@apollo/client';
import { paymentAccountFragment } from '@shared/query/paymentAccount';

export const withdrawalFragment = gql`
  fragment WithdrawalFields on WithdrawalType {
    createdAt
    updatedAt
    deletedAt
    uuid
    amount
    status
    teacher {
      name
    }
  }
`;
export const withdrawalsList = gql`
  ${withdrawalFragment}
  ${paymentAccountFragment}
  query withdrawalsList($filter: WithdrawalListFilterInput, $teacherId: Int!) {
    withdrawalsByTeacher(idTeacher: $teacherId, filters: $filter) {
      count
      rows {
        ...WithdrawalFields
        paymentAccount {
          ...PaymentAccountFields
        }
      }
    }
  }
`;
export const withdrawalRequest = gql`
  mutation withdrawalRequest($input: WithdrawalTeacherInput!) {
    requestWithdrawal(input: $input)
  }
`;

export const cancelWithdrawal = gql`
  mutation cancelWithdrawal($teacherId: Int!, $uuid: UUID!) {
    cancelWithdrawal(idTeacher: $teacherId, uuid: $uuid)
  }
`;
