import { FC, PropsWithChildren, useState } from 'react';

import { PurchasesContext } from '@entities/Purchases/libs/context';
import { Maybe } from '@letsdance/ui-kit';

export const PurchasesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [productUuid, setProductUuid] = useState<Maybe<string>>(null);

  return (
    <PurchasesContext.Provider value={{ productUuid, setProductUuid }}>
      {children}
    </PurchasesContext.Provider>
  );
};
