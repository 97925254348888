import { FC } from 'react';

import cls from './style.module.scss';

export const WikiView: FC = (): JSX.Element => (
  <div className={cls.wiki__wrapper}>
    <iframe
      title="notion"
      width="100%"
      height="100%"
      src="https://e.notionhero.io/e1/p/95ad27e-9dc3d5735b5b6cfcca4075a68d3a453"
    />
  </div>
);
