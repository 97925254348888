import { TeacherInput } from '@/apolloGenerated';

export const getTeacherInput = ({
  description,
  facebook,
  image,
  instagram,
  name,
  socialImage,
  socialsText,
  supplierInfo: { inn, name: supplierName, phone },
  telegram,
  telegramSocial,
  termsOfUse,
  vk,
  youtube,
}: TeacherInput): TeacherInput => ({
  description,
  facebook,
  image,
  instagram,
  name,
  socialImage,
  socialsText,
  supplierInfo: {
    inn,
    name: supplierName,
    phone,
  },
  telegram,
  telegramSocial,
  termsOfUse,
  vk,
  youtube,
});

export const getTeacherInitState = (): TeacherInput => ({
  description: '',
  facebook: null,
  image: '',
  instagram: null,
  name: '',
  socialImage: null,
  socialsText: null,
  supplierInfo: {
    inn: null,
    name: null,
    phone: null,
  },
  telegram: '',
  telegramSocial: null,
  termsOfUse: null,
  vk: null,
  youtube: null,
});
