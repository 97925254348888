import { Route } from 'react-justanother-router';

import { adminRoutes } from './admin';
import { landingRoutes } from './landing';
import { staticRoutes } from './static';

export const routes: Route[] = [
  ...landingRoutes,
  { children: [...adminRoutes], path: '/admin' },
  ...staticRoutes,
];
