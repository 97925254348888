import React, { FC, useEffect } from 'react';

import { GenderEnum, UpdateUserInput, UserType } from '@/apolloGenerated';
import { createTgLink } from '@/shared';
import {
  DropdownItem,
  Select,
  TextField,
  Typography,
  useForm,
} from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface ContactInfoFormProps {
  initValues: UserType;
  onChange(val: UpdateUserInput): void;
  errors?: Record<keyof UpdateUserInput, string[]>;
  readonly?: boolean;
}
export const ContactInfoForm: FC<ContactInfoFormProps> = ({
  errors,
  initValues,
  onChange,
  readonly,
}) => {
  const { onChange: onChangeValue, values } = useForm<UpdateUserInput>(
    console.log,
    initValues,
  );

  useEffect(() => {
    onChange(values);
  }, [values]);

  return (
    <div className={styles.contactInfoForm}>
      <div>
        <div className={styles.contactInfoForm__column}>
          <Select
            size="large"
            placeholder="Пол"
            name="gender"
            initValue={values.gender as GenderEnum}
            renderValue={(val) =>
              val === GenderEnum.Male ? 'Мужской' : 'Женский'
            }
            disabled={readonly}
            onChangeNative={onChangeValue}
            hideOnSelect
            fullWidth>
            <DropdownItem label="Мужской" value={GenderEnum.Male} />
            <DropdownItem label="Женский" value={GenderEnum.Female} />
          </Select>
          <TextField
            label="Фамилия"
            size="large"
            disabled={readonly}
            fullWidth
            error={errors?.last_name}
            name="last_name"
            value={String(values.last_name)}
            onChange={onChangeValue}
          />
          <TextField
            label="Имя"
            size="large"
            disabled={readonly}
            error={errors?.first_name}
            fullWidth
            name="first_name"
            value={String(values.first_name)}
            onChange={onChangeValue}
          />
        </div>
      </div>

      <div>
        <div className={styles.contactInfoForm__column}>
          <TextField
            label="Почта"
            size="large"
            fullWidth
            disabled={readonly}
            error={errors?.email}
            name="email"
            value={String(values.email)}
            onChange={onChangeValue}
          />
          <TextField
            label="Телефон"
            size="large"
            fullWidth
            disabled={readonly}
            error={errors?.phone}
            name="phone"
            value={String(values.phone)}
            onChange={onChangeValue}
          />
          <TextField
            label="Telegram ID"
            size="large"
            fullWidth
            disabled
            value={String(initValues.tgId)}
          />
          <Typography
            tag="a"
            // @ts-ignore
            href={createTgLink(initValues?.username || '')}
            target="_blank"
            color="on-surface-primary-1"
            rel="noreferrer"
            fullWidth>
            <TextField
              label="Telegram"
              size="large"
              fullWidth
              disabled
              value={`t.me/${initValues.username}`}
            />
          </Typography>
        </div>
      </div>
    </div>
  );
};
