import { FC, useEffect, useState } from 'react';

import {
  Errors,
  LoginInput,
  MeQuery,
  useLoginMutation,
} from '@/apolloGenerated';
import { isTeacher, isUser, logoutUser, persistUser } from '@/shared';
import { AuthLoginForm } from '@features/Auth';
import { FormCallback, FormErrorsT } from '@letsdance/ui-kit';
import { me } from '@shared/query';

export interface AuthLoginProps {
  onSuccess?(me: MeQuery['me']): void;
}
export const AuthLogin: FC<AuthLoginProps> = ({ onSuccess }) => {
  const [mutate, { client, error: apiError, loading }] = useLoginMutation();
  const [error, setError] = useState<FormErrorsT<LoginInput>>({});
  const formOnSubmit: FormCallback<LoginInput> = async (
    data,
    setFormErrors,
    setBatchValues,
  ) => {
    const variables = Object.fromEntries(
      Object.entries(data).map(([key, value]) => [key, value.trim()]),
    ) as LoginInput;

    await mutate({
      onCompleted: async (data) => {
        setFormErrors({});
        setBatchValues({
          login: '',
          password: '',
        });
        if (data.login) {
          persistUser(data.login);
          try {
            const { data: userData } = await client.query<MeQuery>({
              query: me,
            });

            if (
              isTeacher(userData?.me.role) &&
              !userData?.me.controlTeacherId
            ) {
              throw new Error('HasNotTeacherId');
            } else if (isUser(userData.me.role)) {
              throw new Error('PermissionDenied');
            }
            onSuccess && onSuccess(userData.me);
          } catch (error) {
            if (error instanceof Error && error.message === 'HasNotTeacherId') {
              setError({
                login: 'Преподователь не прикреплён к аккаунту',
                password: ' ',
              });
            } else {
              setError({ login: 'Недостаточно прав доступа', password: ' ' });
            }
            logoutUser();
          }
        }
      },
      variables,
    });
  };

  useEffect(() => {
    switch (apiError?.message) {
      case Errors.UserNotFound: {
        return setError({ login: 'Пользователь с таким логином не найден' });
      }
      case Errors.InvalidAuthData: {
        return setError({ login: ' ', password: 'Неверный логин или пароль' });
      }
    }
    if (apiError?.message) {
      setError({ login: ' ', password: 'Повторите попытку позже' });
    }
  }, [apiError]);

  return (
    <div>
      <AuthLoginForm
        loading={loading}
        formOnSubmit={formOnSubmit}
        formErrors={error}
      />
    </div>
  );
};
