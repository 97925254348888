import React, { FC, useState } from 'react';

import {
  getOrdersState,
  OrdersTabs,
  useOrdersCounts,
} from '@entities/Purchases/libs/hooks';
import { UserOrdersTable } from '@features/Orders';
import {
  Spacer,
  TabItem,
  Tabs,
  TextField,
  Typography,
} from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface UserOrdersProps {
  uuidUser: string;
  title: string;
}

export const UserOrders: FC<UserOrdersProps> = ({ title, uuidUser }) => {
  const [tab, setTab] = useState<OrdersTabs>(OrdersTabs.All);
  const { active, all, expired } = useOrdersCounts({ uuidUser });

  return (
    <div className={styles.userOrders}>
      <div className={styles.userOrders__head}>
        <Typography variant="head-24" color="on-surface-primary-1">
          {title}
        </Typography>
        <TextField
          disabled
          size="small"
          label="Покупка, курс"
          className={styles.userOrders__search}
        />
      </div>
      <Spacer size={8} />
      <Tabs initValue={tab} onChange={setTab}>
        <TabItem label={`Все (${all})`} value={OrdersTabs.All} />
        <TabItem label={`Активные (${active})`} value={OrdersTabs.Active} />
        <TabItem label={`Истекшие (${expired})`} value={OrdersTabs.Expired} />
      </Tabs>
      <Spacer size={8} />
      <UserOrdersTable uuidUser={uuidUser} state={getOrdersState(tab)} />
    </div>
  );
};
