import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const Chart: FC<IconProps & { small?: boolean }> = ({
  height = 64,
  small,
  width = 64,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`'0 0 ${width} ${height}`}
    fill="none"
    {...rest}>
    {small ? (
      <>
        <path
          d="M6 44H42"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.2 16.7598H8C6.9 16.7598 6 17.6598 6 18.7598V35.9998C6 37.0998 6.9 37.9998 8 37.9998H11.2C12.3 37.9998 13.2 37.0998 13.2 35.9998V18.7598C13.2 17.6598 12.3 16.7598 11.2 16.7598Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.6004 10.3799H22.4004C21.3004 10.3799 20.4004 11.2799 20.4004 12.3799V35.9998C20.4004 37.0998 21.3004 37.9998 22.4004 37.9998H25.6004C26.7004 37.9998 27.6004 37.0998 27.6004 35.9998V12.3799C27.6004 11.2799 26.7004 10.3799 25.6004 10.3799Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.0008 4H36.8008C35.7008 4 34.8008 4.9 34.8008 6V36C34.8008 37.1 35.7008 38 36.8008 38H40.0008C41.1008 38 42.0008 37.1 42.0008 36V6C42.0008 4.9 41.1008 4 40.0008 4Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ) : (
      <>
        <path
          d="M8 58.6667H56"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.9333 22.3463H10.6667C9.2 22.3463 8 23.5463 8 25.013V47.9997C8 49.4664 9.2 50.6664 10.6667 50.6664H14.9333C16.3999 50.6664 17.5999 49.4664 17.5999 47.9997V25.013C17.5999 23.5463 16.3999 22.3463 14.9333 22.3463Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.134 13.8398H29.8674C28.4007 13.8398 27.2007 15.0398 27.2007 16.5065V47.9997C27.2007 49.4664 28.4007 50.6664 29.8674 50.6664H34.134C35.6007 50.6664 36.8007 49.4664 36.8007 47.9997V16.5065C36.8007 15.0398 35.6007 13.8398 34.134 13.8398Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M53.3342 5.33334H49.0675C47.6009 5.33334 46.4009 6.53334 46.4009 8.00001V48C46.4009 49.4667 47.6009 50.6667 49.0675 50.6667H53.3342C54.8009 50.6667 56.0009 49.4667 56.0009 48V8.00001C56.0009 6.53334 54.8009 5.33334 53.3342 5.33334Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    )}
  </svg>
);
