import { FC, PropsWithChildren } from 'react';

import { MeQuery } from '@/apolloGenerated';
import { Footer } from '@shared/ui/Footer/Footer';

import { Header } from '../Header/Header';

import styles from './styles.module.scss';

export interface BaseProps {
  user?: MeQuery['me'];
}

export const Base: FC<PropsWithChildren<BaseProps>> = ({
  children,
  user,
}): JSX.Element => (
  <div className={styles.base}>
    <Header user={user} />
    <div className={styles.content}>
      <main className={styles.base__main}>
        <div className={styles.base__children}>{children}</div>
        <Footer />
      </main>
    </div>
  </div>
);
