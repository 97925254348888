import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const Happyemoji: FC<IconProps & { small?: boolean }> = ({
  height = 64,
  small,
  width = 64,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`'0 0 ${width} ${height}`}
    fill="none"
    {...rest}>
    {small ? (
      <>
        <path
          d="M23.9414 44C34.9872 44 43.9414 35.0456 43.9414 24C43.9414 12.9543 34.9872 4 23.9414 4C12.8957 4 3.94141 12.9543 3.94141 24C3.94141 35.0456 12.8957 44 23.9414 44Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.4219 32C16.5219 35.02 20.0418 37 24.0018 37C27.9618 37 31.4618 35.02 33.5818 32"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ) : (
      <>
        <path
          d="M31.9218 58.6667C46.6495 58.6667 58.5885 46.7275 58.5885 32C58.5885 17.2724 46.6495 5.33334 31.9218 5.33334C17.1942 5.33334 5.25513 17.2724 5.25513 32C5.25513 46.7275 17.1942 58.6667 31.9218 58.6667Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.2292 42.6667C22.0292 46.6934 26.7225 49.3334 32.0025 49.3334C37.2825 49.3334 41.9491 46.6934 44.7758 42.6667"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    )}
  </svg>
);
