import { Maybe, SortEnum } from '@/apolloGenerated';
import { SortType } from '@letsdance/ui-kit';

export const formatAmount = (number: number, fixed?: boolean): string =>
  Intl.NumberFormat('ru-Ru', {
    currency: 'RUB',
    ...(fixed
      ? { maximumFractionDigits: 0 }
      : { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
  }).format(number);
export const formatSort = (sort: Maybe<SortType>): SortEnum | undefined => {
  if (!sort) {
    return undefined;
  }

  return sort === 'asc' ? SortEnum.Asc : SortEnum.Desc;
};
