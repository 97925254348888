import React, { FC, useEffect } from 'react';

import { usePublishCourseMutation } from '@/apolloGenerated';
import {
  Button,
  Dialog,
  DialogFooter,
  toaster,
  Typography,
  WithCloseModal,
} from '@letsdance/ui-kit';

interface PublishCourseModalProps {
  uuid: string;
  onSuccess?(): void;
}
declare global {
  interface ModalProps {
    publishCourse: PublishCourseModalProps;
  }
}
export const PublishCourseModal: FC<
  WithCloseModal<PublishCourseModalProps>
> = ({ onClose, onSuccess, uuid }): JSX.Element => {
  const [publishCourse, { client, data, error, loading }] =
    usePublishCourseMutation();

  const handleSubmit = () => {
    publishCourse({ variables: { uuid } });
  };

  useEffect(() => {
    if (data) {
      toaster.success({
        title: 'Курс успешно опубликован',
      });
      client.cache.evict({ fieldName: 'productsList' });
      client.cache.evict({ fieldName: 'productsCount' });
      client.cache.evict({ args: { uuid }, fieldName: 'product' });
      onSuccess && onSuccess();
    }
    if (error) {
      toaster.error({
        title: 'Ошибка публикации. Обратитесь за помощью в техподдержку',
      });
    }
    if (data || error) {
      onClose();
    }
  }, [data, error]);

  return (
    <Dialog
      title="Опубликовать курс?"
      closed
      width={500}
      footerSlot={
        <DialogFooter
          fullWidth
          endSlot={
            <div className="grid-2">
              <Button color="secondary" fullWidth onClick={onClose}>
                Отмена
              </Button>
              <Button fullWidth onClick={handleSubmit} disabled={loading}>
                Подтвердить
              </Button>
            </div>
          }
        />
      }>
      <Typography variant="body-16" color="on-surface-primary-1">
        Опубликованный курс будет доступен в разделе Активные. Перед активацией
        курса проверьте правильность заполнения информации о нем.
      </Typography>
    </Dialog>
  );
};
