import { FC, useContext } from 'react';

import { PurchasesContext, PurchasesContextType } from '@entities/Purchases';
import { CoursePicker } from '@features/Course';

export const PurchasesProductPicker: FC = () => {
  const { productUuid, setProductUuid } =
    useContext<PurchasesContextType>(PurchasesContext);

  return <CoursePicker initValue={productUuid} onChange={setProductUuid} />;
};
