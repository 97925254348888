import { gql } from '@apollo/client';

export const productPreviewFragment = gql`
  fragment ProductPreviewFields on PreviewProductType {
    active
    url
    description
    back_button_text
  }
`;
export const productFragment = gql`
  ${productPreviewFragment}
  fragment ProductFields on ProductType {
    createdAt
    updatedAt
    deletedAt
    uuid
    img
    name
    description
    preview {
      ...ProductPreviewFields
    }
    successBuyText
    manualBuyText
    activeAcquiring
    faq
    period
    chanel_id
    query
    chat_id
    type
    purchasedCount
    receipts
    haveAccessCount
    status
  }
`;
export const coursesCount = gql`
  query coursesCount($teacherId: Float) {
    productsCount(teacherId: $teacherId) {
      count
      activeCount
      archiveCount
      draftCount
    }
  }
`;

export const course = gql`
  ${productFragment}
  query course($uuid: UUID!) {
    product(uuid: $uuid) {
      ...ProductFields
      tariffs {
        uuid
        name
      }
    }
  }
`;

export const coursesList = gql`
  ${productFragment}
  query coursesList(
    $filters: ProductListFilterInput
    $params: ProductFilter
    $status: [StatusEnum!]
  ) {
    productsList(filters: $filters, params: $params, status: $status) {
      rows {
        ...ProductFields
      }
      count
    }
  }
`;

export const archiveCourse = gql`
  mutation archiveCourse($uuid: UUID!) {
    archiveProduct(uuid: $uuid)
  }
`;

export const publishCourse = gql`
  mutation publishCourse($uuid: UUID!) {
    activateProduct(uuid: $uuid)
  }
`;

export const removeCourse = gql`
  mutation removeCourse($uuid: UUID!) {
    removeProduct(uuid: $uuid)
  }
`;

export const updateCourse = gql`
  mutation updateCourse($uuid: UUID!, $input: ProductInput!) {
    updateProduct(input: $input, uuid: $uuid) {
      uuid
    }
  }
`;

export const createCourse = gql`
  mutation createCourse(
    $publish: Boolean
    $input: ProductInput!
    $idTeacher: Float!
  ) {
    createProduct(publish: $publish, input: $input, idTeacher: $idTeacher) {
      uuid
    }
  }
`;
