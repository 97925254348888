import { TariffInput } from '@/apolloGenerated';

export const getTariffInput = ({
  chanel_id,
  chat_id,
  description,
  name,
  price,
  special_price,
}: TariffInput) => ({
  chanel_id,
  chat_id,
  description,
  name,
  price,
  special_price,
});

export const getTariffInitState = (): TariffInput => ({
  chanel_id: null,
  chat_id: null,
  description: '',
  name: '',
  price: 0,
  special_price: null,
});
