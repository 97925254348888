import { FC } from 'react';

import { Container } from '@/shared';
import { PurchasesProvider } from '@entities/Purchases';
import { Spacer } from '@letsdance/ui-kit';
import { Head } from '@widgets/Layout';
import {
  PurchasesLists,
  PurchasesProductPicker,
  PurchasesStats,
} from '@widgets/Purchases';

export const PurchasesView: FC = () => (
  <PurchasesProvider>
    <Container>
      <Head title="Покупки" />
    </Container>
    <Container>
      <PurchasesProductPicker />
      <Spacer size={12} />
      <PurchasesStats />
    </Container>
    <Spacer size={12} />
    <PurchasesLists />
  </PurchasesProvider>
);
