import { FC, useState } from 'react';

import { useFinanceListsCountQuery } from '@/apolloGenerated';
import { Container, useTeacherId } from '@/shared';
import { ReceiptsTable } from '@features/Orders';
import { WithdrawTable } from '@features/Withdraw';
import { Divider, Spacer, TabItem, Tabs } from '@letsdance/ui-kit';

export enum FinanceTabs {
  Incoming,
  Outcoming,
}
export const FinanceLists: FC = () => {
  const teacherId = useTeacherId()!;
  const { data } = useFinanceListsCountQuery({
    skip: !teacherId,
    variables: { teacherId },
  });
  const receiptCount = data?.ordersList.count || 0;
  const withdrawCount = data?.withdrawalsByTeacher.count || 0;
  const [tab, setTab] = useState<FinanceTabs>(FinanceTabs.Incoming);

  return (
    <div>
      <Container>
        <Tabs initValue={tab} onChange={setTab}>
          <TabItem
            value={FinanceTabs.Incoming}
            label={`Поступление (${receiptCount})`}
          />
          <TabItem
            value={FinanceTabs.Outcoming}
            label={`Вывод (${withdrawCount})`}
          />
        </Tabs>
      </Container>
      <Divider />
      <Spacer size={12} />
      {tab === FinanceTabs.Incoming && <ReceiptsTable />}
      {tab === FinanceTabs.Outcoming && <WithdrawTable />}
    </div>
  );
};
