import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const FavoriteHart: FC<IconProps & { small?: boolean }> = ({
  height = 64,
  small,
  width = 64,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`'0 0 ${width} ${height}`}
    fill="none"
    {...rest}>
    {small ? (
      <>
        <path
          d="M44 26V18C44 8 40 4 30 4H18C8 4 4 8 4 18V30C4 40 8 44 18 44H26"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.6602 28.9796L19.4202 22.7996C20.1002 21.9196 21.3602 21.7596 22.2402 22.4396L25.9002 25.3196C26.7802 25.9996 28.0402 25.8396 28.7202 24.9796L33.3402 19.0195"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.9604 31.6398L39.5204 32.7798C39.8004 33.3398 40.5004 33.8598 41.1204 33.9798L41.8804 34.0998C44.1604 34.4798 44.7004 36.1598 43.0604 37.8198L42.3604 38.5198C41.9004 38.9998 41.6404 39.9198 41.7804 40.5598L41.8804 40.9798C42.5004 43.7398 41.0404 44.7998 38.6404 43.3598L38.1204 43.0598C37.5004 42.6998 36.5004 42.6998 35.8804 43.0598L35.3604 43.3598C32.9404 44.8198 31.4804 43.7398 32.1204 40.9798L32.2204 40.5598C32.3604 39.9198 32.1004 38.9998 31.6404 38.5198L30.9404 37.8198C29.3004 36.1598 29.8404 34.4798 32.1204 34.0998L32.8804 33.9798C33.4804 33.8798 34.2004 33.3398 34.4804 32.7798L35.0404 31.6398C36.1204 29.4598 37.8804 29.4598 38.9604 31.6398Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ) : (
      <>
        <path
          d="M58.6666 34.6667V24C58.6666 10.6667 53.3332 5.33334 39.9999 5.33334H23.9999C10.6666 5.33334 5.33325 10.6667 5.33325 24V40C5.33325 53.3333 10.6666 58.6667 23.9999 58.6667H34.6666"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.5469 38.6395L25.8935 30.3995C26.8003 29.2261 28.4803 29.0128 29.6536 29.9195L34.5336 33.7595C35.7069 34.6661 37.3869 34.4528 38.2936 33.3061L44.4536 25.3594"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.9473 42.1864L52.694 43.7064C53.0673 44.4531 54.0006 45.1464 54.8273 45.3064L55.8406 45.4664C58.8806 45.9731 59.6006 48.2131 57.414 50.4264L56.4806 51.3597C55.8673 51.9997 55.5206 53.2264 55.7073 54.0797L55.8406 54.6397C56.6673 58.3197 54.7206 59.7331 51.5206 57.8131L50.8273 57.4131C50.0006 56.9331 48.6673 56.9331 47.8406 57.4131L47.1473 57.8131C43.9206 59.7597 41.974 58.3197 42.8273 54.6397L42.9606 54.0797C43.1473 53.2264 42.8006 51.9997 42.1873 51.3597L41.254 50.4264C39.0673 48.2131 39.7873 45.9731 42.8273 45.4664L43.8406 45.3064C44.6406 45.1731 45.6006 44.4531 45.974 43.7064L46.7206 42.1864C48.1606 39.2797 50.5073 39.2797 51.9473 42.1864Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    )}
  </svg>
);
