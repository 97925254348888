import { FC } from 'react';

import { useUsersStatsQuery } from '@/apolloGenerated';
import { Container, StatItem, useTeacherId } from '@/shared';
import { Divider } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

const getStat = (stat?: number) => stat || 0;

export const ContactStats: FC = () => {
  const teacherId = useTeacherId();
  const { data: statsData } = useUsersStatsQuery({
    skip: !teacherId,
    variables: { teacherId },
  });
  const stats = statsData?.usersStats;

  const { data: activeStatsData } = useUsersStatsQuery({
    skip: !teacherId,
    variables: { isActive: true, teacherId },
  });
  const activeStats = activeStatsData?.usersStats;

  return (
    <Container className={styles.contactStats}>
      <div className={styles.contactStats__block}>
        <StatItem label="Всего контактов" value={getStat(stats?.count)} />
        <Divider vertical />
        <StatItem label="Мужчины" value={getStat(stats?.maleCount)} />
        <Divider vertical />
        <StatItem label="Женщины" value={getStat(stats?.femaleCount)} />
      </div>
      <div className={styles.contactStats__block}>
        <StatItem
          label="Активные пользователи"
          value={getStat(activeStats?.count)}
        />
        <Divider vertical />
        <StatItem label="Мужчины" value={getStat(activeStats?.maleCount)} />
        <Divider vertical />
        <StatItem label="Женщины" value={getStat(activeStats?.femaleCount)} />
      </div>
    </Container>
  );
};
