import { ProductTypeEnum } from '@/apolloGenerated';

export const courseType: Record<ProductTypeEnum, string> = {
  [ProductTypeEnum.Bachata]: 'Бачата',
  [ProductTypeEnum.Hastl]: 'Хастл',
  [ProductTypeEnum.Kizomba]: 'Кизомба',
  [ProductTypeEnum.Mambo]: 'Мамбо',
  [ProductTypeEnum.Oriental]: 'Ориентал',
  [ProductTypeEnum.Other]: 'Другое',
  [ProductTypeEnum.Salsa]: 'Сальса',
  [ProductTypeEnum.Tango]: 'Танго',
};
