import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const Clock: FC<IconProps> = ({ height = 40, width = 40, ...rest }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}>
    <path
      d="M41.5 26.5C41.5 36.16 33.66 44 24 44C14.34 44 6.5 36.16 6.5 26.5C6.5 16.84 14.34 9 24 9C33.66 9 41.5 16.84 41.5 26.5Z"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24 16V26"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 4H30"
      stroke="white"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
