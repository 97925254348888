import { useEffect } from 'react';

import {
  UpdateUserMutation,
  UpdateUserMutationVariables,
  useUpdateUserMutation,
} from '@/apolloGenerated';
import { MutationResult } from '@apollo/client';
import { toaster } from '@letsdance/ui-kit';

export const useUpdateUser = (): [
  (vars: UpdateUserMutationVariables) => Promise<void>,
  MutationResult<UpdateUserMutation>,
] => {
  const [updateUser, result] = useUpdateUserMutation();

  const onUpdateUser = async ({ input, uuid }: UpdateUserMutationVariables) => {
    await updateUser({
      variables: {
        input: {
          email: input.email,
          first_name: input.first_name,
          gender: input.gender,
          last_name: input.last_name,
          phone: input.phone,
        },
        uuid,
      },
    });
  };

  useEffect(() => {
    if (result.data) {
      toaster.success({ title: 'Пользователь успешно обновлён' });
    }
  }, [result.data, result.error]);

  return [onUpdateUser, result];
};
