import { useEffect } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  CreateTeacherMutation,
  CreateTeacherMutationVariables,
  useCreateTeacherMutation,
} from '@/apolloGenerated';
import { MutationResult } from '@apollo/client';
import { RouterName } from '@app/router';
import { getTeacherInput } from '@entities/Teacher/libs/hooks/getTeacherInput';
import { toaster } from '@letsdance/ui-kit';

export const useCreateTeacher = (): [
  (vars: CreateTeacherMutationVariables) => Promise<void>,
  MutationResult<CreateTeacherMutation>,
] => {
  const { navigate } = useNavigate();
  const [createTeacher, result] = useCreateTeacherMutation();

  const onCreateTeacher = async ({ input }: CreateTeacherMutationVariables) => {
    createTeacher({
      variables: {
        input: getTeacherInput(input),
      },
    });
  };

  useEffect(() => {
    if (result.data) {
      const { client, data } = result;

      client.cache.evict({ fieldName: 'teachersList' });
      navigate(RouterName.AdminTeacherEdit, {
        id: data?.createTeacher.id,
      });
      toaster.success({ title: 'Преподаватель успешно создано' });
    }
  }, [result.data, result.error]);

  return [onCreateTeacher, result];
};
