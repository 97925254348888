import { FC, useContext } from 'react';

import { usePurchaseStatsQuery } from '@/apolloGenerated';
import { StatItem, StatWrap, useTeacherId } from '@/shared';
import { PurchasesContext, PurchasesContextType } from '@entities/Purchases';
import { Divider } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

const getStat = (stat?: number) => stat || 0;

export const PurchasesStats: FC = () => {
  const { productUuid } = useContext<PurchasesContextType>(PurchasesContext);
  const teacherId = useTeacherId()!;
  const { data } = usePurchaseStatsQuery({
    skip: !teacherId,
    variables: { teacherId, uuidProduct: productUuid },
  });
  const stats = data?.ordersStats;
  const usersCounts = data?.ordersStats.usersCounts;

  return (
    <div className={styles.purchaseStats}>
      <StatWrap>
        <StatItem
          label="Всего получено, ₽"
          value={getStat(stats?.financeStats.profit)}
          format
          fixed={false}
        />
      </StatWrap>
      <StatWrap>
        <StatItem label="Пользователи" value={getStat(usersCounts?.all)} />
        <Divider vertical />
        <StatItem label="Мужчины" value={getStat(usersCounts?.male)} />
        <Divider vertical />
        <StatItem label="Женщины" value={getStat(usersCounts?.female)} />
      </StatWrap>
    </div>
  );
};
