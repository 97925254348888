import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const Diagram: FC<IconProps> = ({
  height = 48,
  width = 48,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 48 48"
    fill="none"
    {...rest}>
    <path
      d="M4.5 4V38C4.5 41.32 7.18 44 10.5 44H44.5"
      stroke="white"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 34L19.68 23.28C21.2 21.52 23.9 21.4 25.54 23.06L27.44 24.96C29.08 26.6 31.78 26.5 33.3 24.74L42.5 14"
      stroke="white"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
