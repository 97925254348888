import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const ReceiptAdd: FC<IconProps> = ({
  height = 48,
  width = 48,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 48 48"
    fill="none"
    {...rest}>
    <path
      d="M44.5 12V16.84C44.5 20 42.5 22 39.34 22H32.5V8.02C32.5 5.8 34.32 4 36.54 4C38.72 4.02 40.72 4.9 42.16 6.34C43.6 7.8 44.5 9.8 44.5 12Z"
      stroke="white"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 14V42C4.5 43.66 6.38002 44.6 7.70002 43.6L11.12 41.04C11.92 40.44 13.04 40.52 13.76 41.24L17.08 44.58C17.86 45.36 19.14 45.36 19.92 44.58L23.28 41.22C23.98 40.52 25.1 40.44 25.88 41.04L29.3 43.6C30.62 44.58 32.5 43.64 32.5 42V8C32.5 5.8 34.3 4 36.5 4H14.5H12.5C6.5 4 4.5 7.58 4.5 12V14Z"
      stroke="white"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 20H24"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.5 25.5V14.5"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
