import React, { FC, useState } from 'react';

import { StatusEnum, useCoursesCountQuery } from '@/apolloGenerated';
import { Container, useTeacherId } from '@/shared';
import { CourseListFilter, CourseTable } from '@features/Course';
import {
  Divider,
  InfoDenseIcon,
  Spacer,
  TabItem,
  Tabs,
  Tooltip,
  Typography,
} from '@letsdance/ui-kit';

export const CoursesLists: FC = () => {
  const teacherId = useTeacherId();
  const { data: countsData } = useCoursesCountQuery({
    variables: { teacherId },
  });
  const counts = countsData?.productsCount;
  const [tab, setTab] = useState<StatusEnum | 'all'>(StatusEnum.Active);
  const [search, setSearch] = useState<string>('');

  console.log(setSearch);

  return (
    <div>
      <Container>
        <Tabs initValue={tab} onChange={setTab}>
          <TabItem
            value={StatusEnum.Active}
            label={`Активные (${counts?.activeCount || 0})`}
          />
          <TabItem
            style={{ alignItems: 'center', display: 'flex', gap: 4 }}
            value={StatusEnum.Draft}>
            Черновики ({counts?.draftCount || 0}){' '}
            <Tooltip
              style={{ display: 'flex' }}
              width={300}
              label={
                <Typography variant="body-14">
                  Курс, сохранённый в черновики, не виден пользователям. Для
                  старта продаж переведите его в статус{' '}
                  <Typography tag="span" variant="head-14">
                    Активный.
                  </Typography>
                </Typography>
              }>
              <InfoDenseIcon />
            </Tooltip>
          </TabItem>
          <TabItem
            value={StatusEnum.Archive}
            label={`Архивные (${counts?.archiveCount || 0})`}
          />
          <TabItem value={'all'} label={`Все (${counts?.count || 0})`} />
        </Tabs>
      </Container>
      <Divider />
      <Container>
        <Spacer size={12} />
        <CourseListFilter />
        <Spacer size={4} />
        <CourseTable status={tab === 'all' ? null : tab} searchValue={search} />
      </Container>
    </div>
  );
};
