import React, { FC } from 'react';

import {
  Button,
  Dialog,
  DialogFooter,
  Typography,
  WithCloseModal,
} from '@letsdance/ui-kit';

interface ActionModalModal {
  title: string;
  description?: string;
  submitBtnText?: string;
  onSubmit(): void;
}
declare global {
  interface ModalProps {
    action: ActionModalModal;
  }
}
export const ActionModal: FC<WithCloseModal<ActionModalModal>> = ({
  description,
  onClose,
  onSubmit,
  submitBtnText = 'Подтвердить',
  title,
}): JSX.Element => (
  <Dialog
    title={title}
    closed
    width={500}
    footerSlot={
      <DialogFooter
        fullWidth
        endSlot={
          <div className="grid-2">
            <Button color="secondary" fullWidth onClick={onClose}>
              Отмена
            </Button>
            <Button
              fullWidth
              onClick={() => {
                onSubmit();
                onClose();
              }}>
              {submitBtnText}
            </Button>
          </div>
        }
      />
    }>
    {description && (
      <Typography variant="body-16" color="on-surface-primary-1">
        {description}
      </Typography>
    )}
  </Dialog>
);
