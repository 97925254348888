import { FC, PropsWithChildren } from 'react';

import { MeQuery, RoleEnum } from '@/apolloGenerated';
import { isAdmin, isMarketer, isTeacher, LogoIcon } from '@/shared';
import { HomeRouteName, RouterName } from '@app/router';
import {
  BagIcon,
  BookmarkIcon,
  Header as HeaderUI,
  NavItem,
  useRouterPath,
  UserSquareIcon,
  WalletIcon,
} from '@letsdance/ui-kit';

import { ProfileControl } from '../ProfileControl/ProfileControl';

export interface HeaderProps {
  user?: MeQuery['me'];
  dense?: boolean;
}

export const Header: FC<PropsWithChildren<HeaderProps>> = ({ dense, user }) => {
  const { checkActivePath } = useRouterPath();

  return (
    <HeaderUI
      dense={dense}
      homeLink={HomeRouteName[user?.role || RoleEnum.User]}
      logoSlot={<LogoIcon color="var(--neutrals-1)" />}
      navigationSlot={
        <>
          {isTeacher(user?.role) && (
            <>
              <NavItem
                to={RouterName.AdminCourses}
                icon={BookmarkIcon}
                active={checkActivePath(RouterName.AdminCourses)}
                fullWidth
                hideLabel={dense}>
                Курсы
              </NavItem>
              <NavItem
                to={RouterName.AdminContact}
                icon={UserSquareIcon}
                active={checkActivePath(RouterName.AdminContact)}
                fullWidth
                hideLabel={dense}>
                Контакты
              </NavItem>
              <NavItem
                to={RouterName.AdminPurchases}
                icon={BagIcon}
                active={checkActivePath(RouterName.AdminPurchases)}
                fullWidth
                hideLabel={dense}>
                Покупки
              </NavItem>
              <NavItem
                to={RouterName.AdminFinance}
                icon={WalletIcon}
                active={checkActivePath(RouterName.AdminFinance)}
                fullWidth
                hideLabel={dense}>
                Финансы
              </NavItem>
            </>
          )}
          {(isMarketer(user?.role) || isAdmin(user?.role)) && (
            <>
              <NavItem
                to={RouterName.AdminTeachers}
                icon={UserSquareIcon}
                active={checkActivePath(RouterName.AdminTeachers)}
                fullWidth
                hideLabel={dense}>
                Организ.
              </NavItem>
            </>
          )}
        </>
      }
      bottomSlot={<ProfileControl />}
    />
  );
};
