import React, { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  Maybe,
  TeachersListQuery,
  TeacherSortKeys,
  useTeachersListQuery,
} from '@/apolloGenerated';
import {
  formatAmount,
  formatSort,
  renderCellDate,
  renderCellItemValue,
} from '@/shared';
import { RouterName } from '@app/router';
import {
  Button,
  LoaderOverlay,
  MoreIcon,
  SortType,
  stopEvent,
  TableController,
} from '@letsdance/ui-kit';

const headers = [
  { key: true, sortable: true, title: 'ID', value: 'id', width: 48 },
  { sortable: true, title: 'Дата создания', value: 'createdAt', width: 180 },
  { sortable: true, title: 'Данные', value: 'name' },
  { title: 'Курсов', value: 'productsCount' },
  { title: 'Продаж', value: 'ordersCount' },
  { title: 'Баланс, ₽', value: 'balance' },
  { title: '', value: 'settings', width: 48 },
];
const pageSize = 15;
const DEFAULT_PAGE = 1;

export interface TeachersTableProps {
  isVerified?: boolean;
}

export const TeachersTable: FC<TeachersTableProps> = ({ isVerified }) => {
  const { navigate } = useNavigate();
  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<TeacherSortKeys>(TeacherSortKeys.Id);

  useEffect(() => {
    if (isVerified) {
      setPage(DEFAULT_PAGE);
    }
  }, [isVerified]);

  const {
    data: curData,
    loading,
    previousData,
  } = useTeachersListQuery({
    variables: {
      filter: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
      },
      verified: isVerified || null,
    },
  });

  const data = curData || previousData;

  const rowTemplate = (
    teacher: TeachersListQuery['teachersList']['rows'][0],
  ) => ({
    balance: formatAmount(teacher.balance, true),
    createdAt: renderCellDate({ time: true, timestamp: teacher.createdAt }),
    id: teacher.id,
    name: renderCellItemValue({
      label: teacher.name,
      value: teacher.telegram || '-',
    }),
    ordersCount: String(teacher.ordersCount),
    productsCount: String(teacher.productsCount),
    settings: (
      <Button
        icon
        prependIcon={MoreIcon}
        variant="text"
        size="large"
        onClick={stopEvent}
      />
    ),
  });
  const items = useMemo(
    () => (data?.teachersList.rows || []).map((item) => rowTemplate(item)),
    [data],
  );

  return (
    <div className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        data={items}
        headers={headers}
        total={data?.teachersList.count}
        pageSize={pageSize}
        onChangePage={setPage}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as TeacherSortKeys);
        }}
        onClickRow={(e, teacher) =>
          navigate(RouterName.AdminTeacherEdit, { id: teacher.id })
        }
        notResetPage
      />
    </div>
  );
};
