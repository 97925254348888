import { gql } from '@apollo/client';

export const financeStats = gql`
  query financeStats($teacherId: Int!) {
    ordersStats(args: { teacherId: $teacherId }) {
      ordersCounts {
        all
      }
      financeStats {
        profit
        totalCommission
        totalReceived
        totalWithdrawals
      }
    }
    teacher(id: $teacherId) {
      balance
    }
  }
`;

export const financeListsCount = gql`
  query financeListsCount($teacherId: Int!) {
    ordersList(
      args: { teacherId: $teacherId }
      filters: { pagination: { pageSize: 1, page: 1 } }
      params: { status: Success }
    ) {
      count
    }
    withdrawalsByTeacher(
      idTeacher: $teacherId
      filters: { pagination: { pageSize: 1, page: 1 } }
    ) {
      count
    }
  }
`;

export const purchaseStats = gql`
  query purchaseStats($teacherId: Int!, $uuidProduct: UUID) {
    ordersStats(args: { teacherId: $teacherId, uuidProduct: $uuidProduct }) {
      usersCounts {
        all
        female
        male
      }
      financeStats {
        profit
      }
    }
  }
`;
