import React, { FC } from 'react';

import { ProductTypeEnum } from '@/apolloGenerated';
import { TableCellLabelValue } from '@/shared';
import { courseType } from '@entities/Course';

export interface CourseNameCellProps {
  name: string;
  type: ProductTypeEnum;
}
export const CourseNameCell: FC<CourseNameCellProps> = ({ name, type }) => (
  <TableCellLabelValue label={name} value={courseType[type]} />
);

export const renderCellCourseName = (args: CourseNameCellProps) => (
  <CourseNameCell {...args} />
);
