import { FC, useState } from 'react';

import { StatusEnum, useCoursesListQuery } from '@/apolloGenerated';
import { useTeacherId } from '@/shared';
import { Chip, Maybe } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface CoursePickerProps {
  initValue?: Maybe<string>;
  onChange(value: Maybe<string>): void;
}
export const CoursePicker: FC<CoursePickerProps> = ({
  initValue,
  onChange,
}) => {
  const teacherId = useTeacherId()!;
  const { data } = useCoursesListQuery({
    skip: !teacherId,
    variables: {
      params: {
        teacherId,
      },
      status: [StatusEnum.Active, StatusEnum.Archive],
    },
  });
  const [value, setValue] = useState<Maybe<string>>(initValue || null);

  const handleChange = (val: Maybe<string>) => {
    setValue(val);
    onChange(val);
  };

  return (
    <div className={styles.coursePicker}>
      <Chip onClick={() => handleChange(null)} active={!value}>
        Все курсы
      </Chip>
      {(data?.productsList.rows || []).map((el) => (
        <Chip
          key={el.uuid}
          onClick={() => handleChange(el.uuid)}
          active={value === el.uuid}>
          {el.name}
        </Chip>
      ))}
    </div>
  );
};
