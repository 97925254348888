import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';

import { Typography } from '@letsdance/ui-kit';

import { AUTOMATION } from '../../const/text.conts';

import styles from './styles.module.scss';

export const Automation: FC = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1260px)' });

  return (
    <section
      className={cx(styles.automation, !isDesktop && styles.automation_mobile)}>
      {isDesktop ? (
        <>
          {AUTOMATION &&
            AUTOMATION.map(([sub, title, sup, subtitle]) => (
              <div key={String(title)} className={styles.automation__item}>
                <div className={styles.automation__desc}>
                  <Typography variant={'head-32'}>{sub}</Typography>
                  <Typography variant={'head-48'}>{title}</Typography>
                  {sup && <Typography variant={'head-32'}>{sup}</Typography>}
                </div>
                <Typography variant={'body-20'}>
                  <span
                    dangerouslySetInnerHTML={{ __html: subtitle || '' }}></span>
                </Typography>
              </div>
            ))}
        </>
      ) : (
        <>
          {AUTOMATION &&
            AUTOMATION.map(([sub, title, sup, subtitle, images]) => (
              <div key={String(title)} className={styles.automation__item}>
                <div className={styles.automation__img}>{images}</div>
                <div className={styles.automation__desc}>
                  <Typography
                    variant={'head-32'}
                    color={'on-surface-primary-1'}>
                    {sub}
                  </Typography>
                  <Typography
                    variant={'head-48'}
                    color={'on-surface-primary-1'}>
                    {title}
                  </Typography>
                  {sup && (
                    <Typography
                      variant={'head-32'}
                      color={'on-surface-primary-1'}>
                      {sup}
                    </Typography>
                  )}
                </div>
                <Typography variant={'body-20'} color={'on-surface-primary-1'}>
                  <span
                    dangerouslySetInnerHTML={{ __html: subtitle || '' }}></span>
                </Typography>
              </div>
            ))}
        </>
      )}
    </section>
  );
};
