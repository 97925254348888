import React, { FC } from 'react';

import styles from './styles.module.scss';

export const NotFoundView: FC = (): JSX.Element => (
  <div className={styles.staticPage404}>
    <div className={styles.staticPage404__textWrapper}>
      <h1 className={styles.staticPage404__title}>404</h1>
    </div>
  </div>
);
