import { createContext } from 'react';

import { Maybe } from '@letsdance/ui-kit';

export interface PurchasesContextType {
  productUuid: Maybe<string>;
  setProductUuid(uuid: string): void;
}
const PurchasesContext = createContext<PurchasesContextType>(
  {} as PurchasesContextType,
);

export { PurchasesContext };
