import { ProductInput, ProductType, ProductTypeEnum } from '@/apolloGenerated';

export const getCourseInput = ({
  activeAcquiring,
  chanel_id,
  chat_id,
  description,
  faq,
  img,
  manualBuyText,
  name,
  period,
  preview: { active, back_button_text, description: descriptionPreview, url },
  query,
  successBuyText,
  tariffsUuid,
  type,
}: ProductInput) => ({
  activeAcquiring,
  chanel_id,
  chat_id,
  description,
  faq,
  img,
  manualBuyText,
  name,
  period,
  preview: {
    active,
    back_button_text,
    description: descriptionPreview,
    url,
  },
  query,
  successBuyText,
  tariffsUuid,
  type,
});
type CourseInitType = ProductInput & Pick<ProductType, 'tariffs'>;
export const getCourseInitState = (): CourseInitType => ({
  activeAcquiring: true,
  chanel_id: 0,
  chat_id: null,
  description: '',
  faq: '',
  img: '',
  manualBuyText: null,
  name: '',
  period: null,
  preview: {
    active: false,
    back_button_text: null,
    description: null,
    url: null,
  },
  query: null,
  successBuyText: null,
  tariffs: [],
  tariffsUuid: [],
  type: ProductTypeEnum.Bachata,
});
