import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const LogoIcon: FC<IconProps> = ({
  color = 'currentColor',
  height = 40,
  width = 72,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 40"
    fill="none"
    {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.2526 8.64429V21.1201C19.5982 20.5937 16.9365 19.9032 13.7927 19.9032C10.6489 19.9032 7.91467 20.5011 6.39858 21.1078V8.64429H0V40H6.39858V26.9053C8.49067 26.3081 11.6096 25.9604 13.7927 25.9604C15.9943 25.9604 19.1449 26.3144 21.2526 26.9176V40H27.6544V8.64429H21.2526Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.1111 19.9982C71.1111 6.64244 62.88 0 51.6569 0H0V6.05122H50.0907C59.0268 6.05122 64.7225 10.414 64.7225 19.9982C64.7225 29.5761 59.0268 33.9448 50.0907 33.9448H36.411C39.2078 30.3038 45.1559 28.388 48.5429 25.2235C50.5369 23.3607 52.1404 20.868 52.1404 17.4775C52.1404 14.1947 50.6087 11.6589 48.3776 10.0298C46.2528 8.47753 44.2091 7.82279 41.7817 7.82279C35.8752 7.82279 29.6473 12.0645 29.6473 20.0042H36.0249C36.0249 16.0544 38.8487 13.8712 41.7817 13.8712C43.7458 13.8712 45.7632 15.3972 45.7632 17.4807C45.7632 20.4235 41.9854 22.7259 39.7472 24.034C34.7808 26.9698 29.7255 30.5291 29.7255 36.8578L29.6661 39.9961H50.0875C63.0361 39.9961 71.1111 33.3536 71.1111 19.9982Z"
      fill="#FF457C"
    />
  </svg>
);
