import { FC } from 'react';

import { LoginInput } from '@/apolloGenerated';
import {
  Button,
  FormGenericData,
  LoaderOverlay,
  Spacer,
  TextField,
  useForm,
} from '@letsdance/ui-kit';

const defaultValues: LoginInput = {
  login: '',
  password: '',
};

export const AuthLoginForm: FC<FormGenericData<LoginInput>> = ({
  formErrors,
  formOnSubmit,
  initialValues,
  loading,
}) => {
  const {
    errors: currentError,
    onChange,
    onCmdEnterSubmit,
    onSubmit,
    values,
  } = useForm<LoginInput>(
    formOnSubmit,
    {
      ...defaultValues,
      ...initialValues,
    },
    formErrors,
  );
  const errors = formErrors || currentError;

  return (
    <form onSubmit={onSubmit} onKeyDown={onCmdEnterSubmit}>
      <TextField
        id="login"
        name="login"
        size="large"
        placeholder="Логин"
        value={values.login}
        onChange={onChange}
        error={errors?.login}
        fullWidth
        autoFocus
      />
      <Spacer size={12} />
      <TextField
        id="password"
        name="password"
        type="password"
        size="large"
        placeholder="Пароль"
        initValue={values.password}
        onChange={onChange}
        fullWidth
        error={errors?.password}
      />
      <Spacer size={12} />
      <Button type="submit">Войти</Button>
      <LoaderOverlay show={loading} />
    </form>
  );
};
