import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  Maybe,
  ProductSearchKeysEnum,
  ProductSortKeys,
  ProductType,
  StatusEnum,
  useCoursesListQuery,
} from '@/apolloGenerated';
import {
  Container,
  formatAmount,
  formatSort,
  renderCellDate,
  useTeacherId,
} from '@/shared';
import { RouterName } from '@app/router';
import { renderCellCourseName } from '@entities/Course';
import { LoaderOverlay, SortType, TableController } from '@letsdance/ui-kit';

import { CourseControl } from '../CourseControl/CourseControl';

const headersDefault = [
  { hide: true, key: true, value: 'uuid' },
  { sortable: true, title: 'Дата создания', value: 'createdAt', width: 160 },
  { sortable: true, title: 'Дата обновления', value: 'updatedAt', width: 160 },
  { title: 'Курс', value: 'course' },
  { title: 'Покупки', value: 'purchasedCount' },
  { title: 'Доступ', value: 'haveAccessCount' },
  { title: 'Выручка, ₽', value: 'receipts' },
  { title: ' ', value: 'settings', width: 48 },
];
const headersDraft = [
  { hide: true, key: true, value: 'uuid' },
  { sortable: true, title: 'Дата создания', value: 'createdAt', width: 160 },
  { sortable: true, title: 'Дата обновления', value: 'updatedAt', width: 160 },
  { title: 'Курс', value: 'course' },
  { title: ' ', value: 'settings', width: 48 },
];
const pageSize = 15;

export interface CourseTableProps {
  status: Maybe<StatusEnum>;
  searchValue: string;
}

export const CourseTable: FC<CourseTableProps> = ({ searchValue, status }) => {
  const { navigate } = useNavigate();
  const teacherId = useTeacherId();
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<Maybe<SortType>>(null);
  const [sortBy, setSortBy] = useState<ProductSortKeys>(
    ProductSortKeys.CreatedAt,
  );

  const {
    data: curData,
    loading,
    previousData,
  } = useCoursesListQuery({
    variables: {
      filters: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
        search: {
          search: searchValue,
          searchBy: ProductSearchKeysEnum.Name,
        },
      },
      params: {
        teacherId,
      },
      status: status ? [status] : null,
    },
  });
  const data = curData || previousData;
  const rowTemplate = (course: ProductType) => ({
    course: renderCellCourseName({ name: course.name, type: course.type }),
    createdAt: renderCellDate({ timestamp: course.createdAt }),
    haveAccessCount: String(course.haveAccessCount),
    purchasedCount: String(course.purchasedCount),
    receipts: formatAmount(course.receipts),
    settings: <CourseControl uuid={course.uuid} status={course.status} />,
    updatedAt: renderCellDate({ timestamp: course.updatedAt }),
    uuid: course.uuid,
  });
  const items = useMemo(
    () =>
      (data?.productsList.rows || []).map((item) =>
        rowTemplate(item as ProductType),
      ),
    [data],
  );

  const headers = useMemo(() => {
    if (status === StatusEnum.Draft) {
      return headersDraft;
    }

    return headersDefault;
  }, [status]);

  return (
    <Container className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        data={items}
        headers={headers}
        total={data?.productsList.count}
        onChangePage={setPage}
        pageSize={pageSize}
        initSort={sort}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as ProductSortKeys);
        }}
        onClickRow={(e, item) =>
          navigate(RouterName.AdminCourseEdit, { uuid: item.uuid })
        }
        notResetPage
      />
    </Container>
  );
};
