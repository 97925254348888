import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const EmojiHappy: FC<IconProps> = ({
  height = 48,
  width = 48,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 48 48"
    fill="none"
    {...rest}>
    <path
      d="M18.5 44H30.5C40.5 44 44.5 40 44.5 30V18C44.5 8 40.5 4 30.5 4H18.5C8.5 4 4.5 8 4.5 18V30C4.5 40 8.5 44 18.5 44Z"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.5 19.5C33.1568 19.5 34.5 18.1569 34.5 16.5C34.5 14.8431 33.1568 13.5 31.5 13.5C29.8432 13.5 28.5 14.8431 28.5 16.5C28.5 18.1569 29.8432 19.5 31.5 19.5Z"
      stroke="white"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 19.5C19.1569 19.5 20.5 18.1569 20.5 16.5C20.5 14.8431 19.1569 13.5 17.5 13.5C15.8431 13.5 14.5 14.8431 14.5 16.5C14.5 18.1569 15.8431 19.5 17.5 19.5Z"
      stroke="white"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.3 26.5996H31.7C32.7 26.5996 33.5 27.3996 33.5 28.3996C33.5 33.3796 29.48 37.3996 24.5 37.3996C19.52 37.3996 15.5 33.3796 15.5 28.3996C15.5 27.3996 16.3 26.5996 17.3 26.5996Z"
      stroke="white"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
