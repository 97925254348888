import React, { FC, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import { TeacherInput, TeacherType, useTeacherQuery } from '@/apolloGenerated';
import {
  ActionButtons,
  ApolloErrorService,
  Container,
  FloatDriver,
  FormSection,
} from '@/shared';
import { RouterName } from '@app/router';
import {
  TeacherForm,
  TeacherInputSteps,
  useUpdateTeacher,
} from '@entities/Teacher';
import { UsersControlTable } from '@features/Contact';
import {
  Button,
  LoaderOverlay,
  PlusDenseIcon,
  Spacer,
  toaster,
} from '@letsdance/ui-kit';
import { Head } from '@widgets/Layout';

export interface TeacherEditViewProps {
  params: {
    id: string;
  };
}

export const TeacherEditView: FC<TeacherEditViewProps> = ({ params }) => {
  const id = +params.id;
  const { navigate } = useNavigate();
  const [updateCourse, { error, loading: saveLoading }] = useUpdateTeacher();
  const [teacherData, setTeacherData] = useState<TeacherInput>();
  const [isOpenAdministratorCreate, setIsOpenAdministratorCreate] =
    useState<boolean>(false);

  const { data, loading, refetch } = useTeacherQuery({
    variables: {
      id,
    },
  });
  const teacher = data?.teacher;

  const onClose = () => {
    navigate(RouterName.AdminTeachers);
  };
  const onSave = async () => {
    if (!teacherData) {
      return toaster.error({ title: 'Отсутствуют данные для обновления' });
    }
    await updateCourse({
      id,
      input: teacherData,
    });
    refetch();
  };

  const handleCloseFloatDivider = () => {
    setIsOpenAdministratorCreate(false);
  };

  return (
    <>
      <LoaderOverlay show={loading || saveLoading} />
      <Container slim>
        <Head title={teacher?.name || ''} onBack={onClose} />
        <TeacherInputSteps data={teacher} />
      </Container>
      <Spacer size={8} />
      <ActionButtons
        actions={[
          {
            handler: onClose,
            label: 'Отменить',
          },
          {
            handler: onSave,
            label: 'Сохранить изменения',
          },
        ]}
      />
      <Spacer size={20} />
      <Container slim>
        {data && (
          <TeacherForm
            initValues={data.teacher as TeacherType}
            onChange={setTeacherData}
            errors={
              ApolloErrorService.getFirstError(error?.graphQLErrors)?.extensions
                ?.validationErrors
            }
          />
        )}
        <FormSection
          title="Администраторы"
          rightSlot={
            <Button
              onClick={() => setIsOpenAdministratorCreate(true)}
              prependIcon={PlusDenseIcon}
              size="small">
              Администратор
            </Button>
          }>
          <UsersControlTable teacherId={id} />
        </FormSection>
        <FloatDriver
          isOpen={isOpenAdministratorCreate}
          onClose={handleCloseFloatDivider}>
          123123
        </FloatDriver>
      </Container>
    </>
  );
};
