import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const User: FC<IconProps & { small?: boolean }> = ({
  height = 64,
  small,
  width = 64,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`'0 0 ${width} ${height}`}
    fill="none"
    {...rest}>
    {small ? (
      <>
        <path
          d="M36 37.7194H34.48C32.88 37.7194 31.36 38.3394 30.24 39.4594L26.82 42.8394C25.26 44.3794 22.72 44.3794 21.16 42.8394L17.74 39.4594C16.62 38.3394 15.08 37.7194 13.5 37.7194H12C8.68 37.7194 6 35.0596 6 31.7796V9.95947C6 6.67947 8.68 4.01953 12 4.01953H36C39.32 4.01953 42 6.67947 42 9.95947V31.7796C42 35.0396 39.32 37.7194 36 37.7194Z"
          stroke="white"
          strokeWidth="2.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.9998 19.9996C26.5736 19.9996 28.66 17.9132 28.66 15.3396C28.66 12.766 26.5736 10.6797 23.9998 10.6797C21.4262 10.6797 19.3398 12.766 19.3398 15.3396C19.3398 17.9132 21.4262 19.9996 23.9998 19.9996Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32 31.3198C32 27.7198 28.42 24.7998 24 24.7998C19.58 24.7998 16 27.7198 16 31.3198"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ) : (
      <>
        {' '}
        <path
          d="M48 50.2925H45.9733C43.84 50.2925 41.8133 51.1192 40.32 52.6125L35.76 57.1192C33.68 59.1725 30.2933 59.1725 28.2133 57.1192L23.6533 52.6125C22.16 51.1192 20.1067 50.2925 18 50.2925H16C11.5733 50.2925 8 46.7461 8 42.3728V13.2793C8 8.90598 11.5733 5.35939 16 5.35939H48C52.4267 5.35939 56 8.90598 56 13.2793V42.3728C56 46.7195 52.4267 50.2925 48 50.2925Z"
          stroke="white"
          strokeWidth="2.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.9999 26.6662C35.4316 26.6662 38.2135 23.8843 38.2135 20.4528C38.2135 17.0213 35.4316 14.2396 31.9999 14.2396C28.5684 14.2396 25.7866 17.0213 25.7866 20.4528C25.7866 23.8843 28.5684 26.6662 31.9999 26.6662Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.6668 41.7597C42.6668 36.9597 37.8935 33.0664 32.0002 33.0664C26.1068 33.0664 21.3335 36.9597 21.3335 41.7597"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    )}
  </svg>
);
