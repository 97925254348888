import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const CheckCircle: FC<IconProps> = ({
  height = 30,
  width = 30,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}>
    <circle cx="15.2954" cy="15" r="15" fill="#005BFE" />
    <path
      d="M7.79541 15.5L11.1235 18.7542C12.3659 19.9689 14.3771 19.8775 15.5041 18.5551L22.7954 10"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);
