import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-justanother-router';
import moment from 'moment';

import {
  Maybe,
  OrdersListQuery,
  OrderSortKeys,
  useOrdersListQuery,
} from '@/apolloGenerated';
import {
  formatAmount,
  formatSort,
  renderCellDate,
  renderCellItemValue,
  useTeacherId,
} from '@/shared';
import { RouterName } from '@app/router';
import { LoaderOverlay, SortType, TableController } from '@letsdance/ui-kit';

const headers = [
  { hide: true, key: true, value: 'id' },
  { sortable: true, title: 'Покупка', value: 'createdAt' },
  { title: 'Курс', value: 'course' },
  { title: 'Тариф', value: 'tariff' },
  { title: 'Пользователь', value: 'user' },
  { title: 'Начало', value: 'startDate' },
  { title: 'Завершение', value: 'endDate' },
  { title: 'Потрачено, ₽ ', value: 'price' },
];
const pageSize = 10;

export interface PurchasesOrdersTableProps {
  uuidProduct: Maybe<string>;
  state?: boolean;
}
export const PurchasesOrdersTable: FC<PurchasesOrdersTableProps> = ({
  state,
  uuidProduct,
}) => {
  const { urlFor } = useNavigate();
  const teacherId = useTeacherId()!;
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<OrderSortKeys>(OrderSortKeys.CreatedAt);

  const {
    data: curData,
    loading,
    previousData,
  } = useOrdersListQuery({
    skip: !teacherId,
    variables: {
      filter: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
      },
      isExpired: state,
      teacherId,
      uuidProduct,
    },
  });
  const data = curData || previousData;

  const rowTemplate = (order: OrdersListQuery['ordersList']['rows'][0]) => ({
    course: renderCellItemValue({
      label: order.product.name,
      value: order.product.type,
    }),
    createdAt: renderCellItemValue({
      label: `ID: ${order.id}`,
      value: moment(order.createdAt).format('DD MMM YYYY HH:mm'),
    }),
    endDate: order.expired_date
      ? renderCellDate({
          time: true,
          timestamp: order.expired_date,
        })
      : '-',
    id: String(order.id),
    price: formatAmount(order.price),
    startDate: renderCellDate({ time: true, timestamp: order.createdAt }),
    tariff: order.tariff.name,
    user: renderCellItemValue({
      label: `${order.user.last_name || ''} ${order.user.first_name}`,
      onClick: () =>
        window.open(
          urlFor(RouterName.AdminContactEdit, { uuid: order.user.uuid }),
          '_blank',
        ),
      value: order.user.phone || order.user.email,
    }),
  });
  const items = useMemo(
    () => (data?.ordersList.rows || []).map(rowTemplate),
    [data],
  );

  return (
    <div className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        data={items}
        headers={headers}
        total={data?.ordersList.count}
        onChangePage={setPage}
        pageSize={pageSize}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as OrderSortKeys);
        }}
        notResetPage
      />
    </div>
  );
};
