import { useEffect } from 'react';

import {
  UpdateTariffMutation,
  UpdateTariffMutationVariables,
  useUpdateTariffMutation,
} from '@/apolloGenerated';
import { MutationResult } from '@apollo/client';
import { getTariffInput } from '@entities/Tariff/libs/hooks/getTariffInput';
import { toaster } from '@letsdance/ui-kit';

export const useUpdateTariff = (): [
  (vars: UpdateTariffMutationVariables) => Promise<void>,
  MutationResult<UpdateTariffMutation>,
] => {
  const [updateTariff, result] = useUpdateTariffMutation();

  const onUpdateTariff = async ({
    input,
    uuid,
  }: UpdateTariffMutationVariables) => {
    await updateTariff({
      variables: {
        input: getTariffInput(input),
        uuid,
      },
    });
  };

  useEffect(() => {
    if (result.data) {
      toaster.success({ title: 'Тариф успешно обновлено' });
      result.client.cache.evict({
        args: { uuid: result.data.updateTariff.uuid },
        fieldName: 'tariff',
      });
    }
  }, [result.data, result.error]);

  return [onUpdateTariff, result];
};
