import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const Cup: FC<IconProps & { small?: boolean }> = ({
  height = 64,
  small,
  width = 64,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`'0 0 ${width} ${height}`}
    fill="none"
    {...rest}>
    {small ? (
      <>
        <path
          d="M24.3008 33V37.2"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.3008 44H34.3008V42C34.3008 39.8 32.5008 38 30.3008 38H18.3008C16.1008 38 14.3008 39.8 14.3008 42V44Z"
          stroke="white"
          strokeWidth="2.5"
          strokeMiterlimit="10"
        />
        <path
          d="M12.3008 44H36.3008"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24 32C16.26 32 10 25.74 10 18V12C10 7.58 13.58 4 18 4H30C34.42 4 38 7.58 38 12V18C38 25.74 31.74 32 24 32Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.9381 23.3002C9.43812 22.8202 8.11812 21.9402 7.07812 20.9002C5.27813 18.9002 4.07812 16.5002 4.07812 13.7002C4.07812 10.9002 6.27813 8.7002 9.07812 8.7002H10.3781C9.97813 9.6202 9.77812 10.6402 9.77812 11.7002V17.7002C9.77812 19.7002 10.1981 21.5802 10.9381 23.3002Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.0586 23.3002C38.5586 22.8202 39.8786 21.9402 40.9186 20.9002C42.7186 18.9002 43.9186 16.5002 43.9186 13.7002C43.9186 10.9002 41.7186 8.7002 38.9186 8.7002H37.6186C38.0186 9.6202 38.2186 10.6402 38.2186 11.7002V17.7002C38.2186 19.7002 37.7986 21.5802 37.0586 23.3002Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ) : (
      <>
        <path
          d="M32.4011 44V49.6"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.0676 58.6667H45.7343V56C45.7343 53.0667 43.3343 50.6667 40.401 50.6667H24.401C21.4676 50.6667 19.0676 53.0667 19.0676 56V58.6667Z"
          stroke="white"
          strokeWidth="2.5"
          strokeMiterlimit="10"
        />
        <path
          d="M16.4011 58.6667H48.4011"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.9999 42.6667C21.6799 42.6667 13.3333 34.32 13.3333 24V16C13.3333 10.1067 18.1066 5.33333 23.9999 5.33333H39.9999C45.8933 5.33333 50.6666 10.1067 50.6666 16V24C50.6666 34.32 42.3199 42.6667 31.9999 42.6667Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5842 31.0669C12.5842 30.4269 10.8242 29.2536 9.4375 27.8669C7.0375 25.2003 5.4375 22.0003 5.4375 18.2669C5.4375 14.5336 8.37083 11.6003 12.1042 11.6003H13.8375C13.3042 12.8269 13.0375 14.1869 13.0375 15.6003V23.6003C13.0375 26.2669 13.5975 28.7736 14.5842 31.0669Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.4114 31.0669C51.4114 30.4269 53.1714 29.2536 54.558 27.8669C56.958 25.2003 58.558 22.0003 58.558 18.2669C58.558 14.5336 55.6247 11.6003 51.8914 11.6003H50.158C50.6914 12.8269 50.958 14.1869 50.958 15.6003V23.6003C50.958 26.2669 50.398 28.7736 49.4114 31.0669Z"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    )}
  </svg>
);
