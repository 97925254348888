import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const Doughnut: FC<IconProps & { small?: boolean }> = ({
  height = 64,
  small,
  width = 64,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`'0 0 ${width} ${height}`}
    fill="none"
    {...rest}>
    {small ? (
      <>
        <path
          d="M8.04 11.94C5.5 15.3 4 19.48 4 24C4 35.04 12.96 44 24 44C35.04 44 44 35.04 44 24C44 12.96 35.04 4 24 4"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 24C10 31.74 16.26 38 24 38C31.74 38 38 31.74 38 24C38 16.26 31.74 10 24 10"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24 32C28.42 32 32 28.42 32 24C32 19.58 28.42 16 24 16"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ) : (
      <>
        <path
          d="M10.7202 15.92C7.3335 20.4 5.3335 25.9733 5.3335 32C5.3335 46.72 17.2802 58.6667 32.0002 58.6667C46.7202 58.6667 58.6668 46.72 58.6668 32C58.6668 17.28 46.7202 5.33333 32.0002 5.33333"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3335 32C13.3335 42.32 21.6802 50.6667 32.0002 50.6667C42.3202 50.6667 50.6668 42.32 50.6668 32C50.6668 21.68 42.3202 13.3333 32.0002 13.3333"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32 42.6667C37.8933 42.6667 42.6667 37.8933 42.6667 32C42.6667 26.1067 37.8933 21.3333 32 21.3333"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    )}
  </svg>
);
