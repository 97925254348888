import { FC } from 'react';

import { Container } from '@/shared';
import { RouterName } from '@app/router';
import { Button, PlusDenseIcon } from '@letsdance/ui-kit';
import { CoursesLists } from '@widgets/Courses';
import { Head } from '@widgets/Layout';

export const CoursesView: FC = () => (
  <div>
    <Container>
      <Head
        title="Курсы"
        rightSlot={
          <Button to={RouterName.AdminCourseCreate} prependIcon={PlusDenseIcon}>
            Онлайн-курс
          </Button>
        }
      />
    </Container>
    <CoursesLists />
  </div>
);
