import React, { FC, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import { TeacherInput, TeacherType, useTeacherQuery } from '@/apolloGenerated';
import {
  ActionButtons,
  ApolloErrorService,
  Container,
  useTeacherId,
} from '@/shared';
import { RouterName } from '@app/router';
import {
  TeacherForm,
  TeacherInputSteps,
  useUpdateTeacher,
} from '@entities/Teacher';
import { LoaderOverlay, Spacer } from '@letsdance/ui-kit';
import { ActionButton } from '@shared/ui/ActionButtons/ActionButtons';
import { Head } from '@widgets/Layout';

export const ProfileTeacherView: FC = () => {
  const teacherId = useTeacherId()!;
  const { navigate } = useNavigate();
  const [updateTeacher, { error, loading: saveLoading }] = useUpdateTeacher();
  const [teacherData, setTeacherData] = useState<TeacherInput>();

  const { data, loading, refetch } = useTeacherQuery({
    skip: !teacherId,
    variables: {
      id: teacherId,
    },
  });
  const teacher = data?.teacher;

  const onClose = () => {
    navigate(RouterName.AdminCourses);
  };
  const onSave = async () => {
    await updateTeacher({
      id: teacherId,
      input: teacherData!,
    });
    refetch();
  };

  const actions: ActionButton[] = [
    {
      handler: onClose,
      label: 'Отменить',
    },
    {
      handler: onSave,
      label: 'Сохранить изменения',
    },
  ];

  return (
    <>
      <LoaderOverlay show={loading || saveLoading} />
      <Container slim>
        <Head title="Профиль" />
        <TeacherInputSteps data={teacher} />
      </Container>
      <Spacer size={8} />
      <ActionButtons actions={actions} />
      <Spacer size={20} />
      <Container slim>
        {data && (
          <TeacherForm
            initValues={data.teacher as TeacherType}
            onChange={setTeacherData}
            errors={
              ApolloErrorService.getFirstError(error?.graphQLErrors)?.extensions
                ?.validationErrors
            }
          />
        )}
      </Container>
    </>
  );
};
