import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const Cart: FC<IconProps> = ({ height = 48, width = 48, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 48 48"
    fill="none"
    {...rest}>
    <path
      d="M4.5 4H7.98002C10.14 4 11.84 5.86 11.66 8L10 27.92C9.72 31.18 12.3 33.98 15.58 33.98H36.88C39.76 33.98 42.28 31.62 42.5 28.76L43.58 13.76C43.82 10.44 41.3 7.74 37.96 7.74H12.14"
      stroke="white"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33 44C34.3808 44 35.5 42.8808 35.5 41.5C35.5 40.1192 34.3808 39 33 39C31.6192 39 30.5 40.1192 30.5 41.5C30.5 42.8808 31.6192 44 33 44Z"
      stroke="white"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 44C18.3807 44 19.5 42.8808 19.5 41.5C19.5 40.1192 18.3807 39 17 39C15.6193 39 14.5 40.1192 14.5 41.5C14.5 42.8808 15.6193 44 17 44Z"
      stroke="white"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.5 16H42.5"
      stroke="white"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
