import React, { FC, useEffect } from 'react';

import { TariffInput, TariffType } from '@/apolloGenerated';
import { FormSection, handleNumberInput } from '@/shared';
import { Spacer, TextField, Typography, useForm } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface TariffFormProps {
  initValues: TariffType;
  onChange(val: TariffInput): void;
  errors?: Record<keyof TariffInput, string[]>;
  readonly?: boolean;
}
export const TariffForm: FC<TariffFormProps> = ({
  errors,
  initValues,
  onChange,
  readonly,
}) => {
  const {
    onChange: onChangeValue,
    setBatchValues,
    values,
  } = useForm<TariffInput>(console.log, initValues);

  useEffect(() => {
    onChange(values);
  }, [values]);

  return (
    <div className={styles.tariffForm}>
      <FormSection title="Основная информация">
        <div className="grid-2">
          <TextField
            label="Название тарифа"
            size="large"
            disabled={readonly}
            fullWidth
            error={errors?.name}
            name="name"
            initValue={values.name!}
            onChange={onChangeValue}
          />
          <div className="grid-2">
            <TextField
              label="Цена тарифа, ₽"
              size="large"
              disabled={readonly}
              fullWidth
              error={errors?.price}
              name="price"
              onKeyPress={handleNumberInput()}
              initValue={String(values.price)}
              onChange={(e) => setBatchValues({ price: +e.target.value })}
            />
            <TextField
              label="Спец. цена тарифа, ₽"
              size="large"
              disabled={readonly}
              fullWidth
              error={errors?.special_price}
              name="price"
              onKeyPress={handleNumberInput()}
              initValue={String(values.special_price || '')}
              onChange={(e) =>
                setBatchValues({
                  special_price: e.target.value ? +e.target.value : null,
                })
              }
            />
          </div>
        </div>
        <Spacer size={10} />
        <TextField
          tag="textarea"
          label="Описание"
          size="large"
          style={{ height: 200 }}
          disabled={readonly}
          fullWidth
          error={errors?.description}
          name="description"
          initValue={values.description!}
          onChange={onChangeValue}
        />
      </FormSection>
      <FormSection title="Доступы">
        <Typography variant="body-16" color="on-surface-primary-1">
          Заполните Telegram чат или Telegram канал, если хотите, чтобы они были
          отличными от базовых.
        </Typography>
        <Spacer size={6} />
        <div className="grid-2">
          <TextField
            label="ID Telegram канала"
            size="large"
            disabled={readonly}
            fullWidth
            error={errors?.chanel_id}
            name="chanel_id"
            initValue={String(values.chanel_id || '')}
            onKeyPress={handleNumberInput({ allowNegative: true })}
            onChange={(e) =>
              setBatchValues({
                chanel_id: e.target.value ? Number(e.target.value) : 0,
              })
            }
          />
          <TextField
            label="ID Telegram чата"
            size="large"
            disabled={readonly}
            fullWidth
            error={errors?.chat_id}
            name="chat_id"
            initValue={String(values.chat_id || '')}
            onKeyPress={handleNumberInput({ allowNegative: true })}
            onChange={(e) =>
              setBatchValues({
                chat_id: e.target.value ? Number(e.target.value) : null,
              })
            }
          />
        </div>
      </FormSection>
    </div>
  );
};
