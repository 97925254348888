import { gql } from '@apollo/client';

export const paymentAccountFragment = gql`
  fragment PaymentAccountFields on PaymentAccountType {
    createdAt
    updatedAt
    deletedAt
    uuid
    name
    inn
    bik
    accountNumber
  }
`;

export const paymentAccountsList = gql`
  ${paymentAccountFragment}
  query paymentAccountsList($teacherId: Int!) {
    paymentsByTeacher(idTeacher: $teacherId) {
      ...PaymentAccountFields
    }
  }
`;
