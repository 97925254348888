import { FC, PropsWithChildren } from 'react';
import { Redirect } from 'react-justanother-router';

import { getUserRole, HomeRouteName } from '@app/router';

import styles from './styles.module.scss';

export const AuthLayout: FC<PropsWithChildren> = ({
  children,
}): JSX.Element => {
  const role = getUserRole();

  if (role && HomeRouteName[role]) {
    return <Redirect to={HomeRouteName[role]} />;
  }

  return (
    <div className={styles.auth}>
      <div className={styles.auth__container}>
        <div className={styles.auth__content}>{children}</div>
      </div>
    </div>
  );
};
