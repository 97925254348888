import { FC } from 'react';

import { useModal } from '@/shared';
import { CloseIcon } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface WithdrawCancelProps {
  uuid: string;
  onSuccess(): void;
}
export const WithdrawCancel: FC<WithdrawCancelProps> = ({
  onSuccess,
  uuid,
}) => {
  const openCancelWithdraw = useModal('cancelWithdraw');

  return (
    <div className={styles.withdrawCancel}>
      <CloseIcon
        className={styles.withdrawCancel__icon}
        onClick={() =>
          openCancelWithdraw({
            onSuccess,
            uuid,
          })
        }
      />
    </div>
  );
};
