import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { openSupportTg } from '@/shared';
import {
  Button,
  FacebookIcon,
  InstagramIcon,
  TelegramIcon,
  Typography,
  VkIcon,
} from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export const Footer: FC = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1260px)' });

  return (
    <footer className={styles.footer}>
      <div className={styles.footer__wrapper}>
        <div className={styles.footer__title}>
          <Typography
            variant={isDesktop ? 'head-48' : 'head-32'}
            color={'on-primary-1'}
            className={styles.footer__heading}>
            Остались вопросы?
          </Typography>
          <div className={styles.footer__group}>
            <Typography
              variant={isDesktop ? 'body-20' : 'body-16'}
              color={'on-primary-1'}
              className={styles.footer__subtitle}>
              <b>Свяжитесь с нами</b> и мы ответим {!isDesktop && <br />} на все
              возникшие вопросы.
            </Typography>
            <Button fullWidth={!isDesktop} size="large" onClick={openSupportTg}>
              Оставить заявку
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.footer__bottom}>
        <div className={styles.footer__social}>
          <TelegramIcon width={50} height={50} />
          <VkIcon width={50} height={50} />
          <a
            href="https://www.instagram.com/h2.dance"
            target="_blank"
            className={styles.footer__link}
            rel="noreferrer">
            <InstagramIcon width={50} height={50} />
          </a>
          <FacebookIcon width={50} height={50} />
        </div>
        <div className={styles.footer__text}>
          <Typography
            variant={isDesktop ? 'body-16' : 'body-14'}
            tag={'div'}
            className={styles.footer__menu}>
            <a href="/docs/policy.pdf" target="_blank">
              Политика конфиденциальности
            </a>
            <a href="/docs/oferta.pdf" target="_blank">
              Публичная оферта
            </a>
          </Typography>
          <Typography variant={isDesktop ? 'body-16' : 'body-14'}>
            © 2024 H2Dance. Все права защищены
          </Typography>
          {isDesktop && <Typography variant={'head-32'}>H2Dance</Typography>}
        </div>
        {!isDesktop && <Typography variant={'head-24'}>H2Dance</Typography>}
      </div>
    </footer>
  );
};
