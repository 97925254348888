import React, { FC, useEffect, useState } from 'react';
import { Redirect, useNavigate } from 'react-justanother-router';

import {
  ProductInput,
  ProductType,
  StatusEnum,
  useCourseQuery,
} from '@/apolloGenerated';
import {
  ActionButtons,
  ApolloErrorService,
  Container,
  useModal,
} from '@/shared';
import { RouterName } from '@app/router';
import {
  CourseForm,
  CourseInputSteps,
  useUpdateCourse,
} from '@entities/Course';
import { Divider, LoaderOverlay, Spacer, toaster } from '@letsdance/ui-kit';
import { ActionButton } from '@shared/ui/ActionButtons/ActionButtons';
import { Head } from '@widgets/Layout';

export interface CourseEditViewProps {
  params: {
    uuid: string;
  };
  query: {
    readonly?: boolean;
  };
}

export const CourseEditView: FC<CourseEditViewProps> = ({
  params: { uuid },
  query,
}) => {
  const openPublishModal = useModal('publishCourse');
  const { navigate } = useNavigate();
  const [updateCourse, { error, loading: saveLoading }] = useUpdateCourse();
  const [courseData, setCourseData] = useState<ProductInput>();

  const { data, loading, refetch } = useCourseQuery({
    variables: {
      uuid,
    },
  });
  const product = data?.product;
  const initTariffsUuid = product?.tariffs.map((el) => el.uuid);
  const isDraft = product?.status === StatusEnum.Draft;
  const isArchive = product?.status === StatusEnum.Archive;
  const isReadonly = !!query.readonly;

  const onClose = () => {
    navigate(RouterName.AdminCourses);
  };
  const onPublish = () => {
    openPublishModal({ uuid });
  };
  const onSave = async () => {
    if (!courseData) {
      return toaster.error({ title: 'Отсутствуют данные для обновления' });
    }
    await updateCourse({
      input: courseData,
      uuid,
    });
    refetch();
  };

  useEffect(() => {
    if (
      courseData &&
      JSON.stringify(initTariffsUuid) !==
        JSON.stringify(courseData?.tariffsUuid)
    ) {
      updateCourse({
        input: {
          ...product,
          tariffsUuid: courseData.tariffsUuid,
        } as ProductInput,
        uuid,
      });
    }
  }, [courseData?.tariffsUuid]);

  const actions: ActionButton[] = isReadonly
    ? [
        {
          handler: onClose,
          label: 'Отменить',
        },
        {
          handler: () => navigate(RouterName.AdminCourseEdit, { uuid }),
          label: 'Редактировать',
        },
      ]
    : [
        {
          handler: onClose,
          label: 'Отменить',
        },
        {
          handler: onSave,
          label: isDraft ? 'Сохранить черновик' : 'Сохранить изменения',
        },
        ...(isDraft
          ? [
              {
                handler: onPublish,
                label: 'Опубликовать',
              },
            ]
          : []),
      ];

  if (isArchive && !isReadonly) {
    return <Redirect to={RouterName.AdminCourses} />;
  }

  return (
    <>
      <LoaderOverlay show={loading || saveLoading} />
      <Container slim>
        <Head title={product?.name || ''} onBack={onClose} />
        <CourseInputSteps data={product} />
      </Container>
      {isArchive ? (
        <Divider />
      ) : (
        <>
          <Spacer size={8} />
          <ActionButtons actions={actions} />
        </>
      )}
      <Spacer size={20} />
      <Container slim>
        {data && (
          <CourseForm
            initValues={data.product as ProductType}
            onChange={setCourseData}
            errors={
              ApolloErrorService.getFirstError(error?.graphQLErrors)?.extensions
                ?.validationErrors
            }
            readonly={isReadonly}
          />
        )}
      </Container>
    </>
  );
};
