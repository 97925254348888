import { gql } from '@apollo/client';

export const userFragment = gql`
  fragment UserFields on UserType {
    createdAt
    updatedAt
    deletedAt
    uuid
    tgId
    username
    first_name
    last_name
    vk
    facebook
    instagram
    description
    language
    role
    email
    phone
    ordersCount
    haveAccessCount
    controlTeacherId
    receipts
    gender
  }
`;

export const user = gql`
  ${userFragment}
  query User($uuid: UUID!) {
    user(uuid: $uuid) {
      ...UserFields
    }
  }
`;

export const usersStats = gql`
  query usersStats($teacherId: Int, $isActive: Boolean) {
    usersStats(
      params: { teacherId: $teacherId }
      args: { isActive: $isActive }
    ) {
      count
      maleCount
      femaleCount
    }
  }
`;

export const usersList = gql`
  query usersList(
    $filters: UserListFilterInput
    $teacherId: Int
    $isActive: Boolean
  ) {
    users(
      filters: $filters
      params: { teacherId: $teacherId }
      args: { isActive: $isActive }
    ) {
      rows {
        createdAt
        uuid
        first_name
        last_name
        username
        phone
        email
        receipts
        haveAccessCount
        ordersCount
      }
      count
    }
  }
`;

export const usersControlTeacherList = gql`
  query usersControlTeacherList(
    $filters: UserControlTeacherListFilterInput
    $teacherId: Float!
  ) {
    usersControlTeacher(filters: $filters, teacherId: $teacherId) {
      rows {
        createdAt
        uuid
        first_name
        last_name
        username
        phone
        email
        receipts
        haveAccessCount
        ordersCount
      }
      count
    }
  }
`;

export const updateUser = gql`
  mutation updateUser($uuid: UUID!, $input: UpdateUserInput!) {
    updateUser(uuid: $uuid, input: $input) {
      uuid
    }
  }
`;

export const deactivateUserTeacher = gql`
  mutation deactivateUserTeacher($uuid: UUID!) {
    deactivateUserTeacher(uuid: $uuid) {
      uuid
    }
  }
`;
