import { Redirect } from 'react-justanother-router';
import { jwtDecode } from 'jwt-decode';

import { RoleEnum, UserType } from '@/apolloGenerated';
import { loadUser } from '@/shared';
import { RouterName } from '@app/router/types';
import { Maybe } from '@letsdance/ui-kit';
import { NotFoundView } from '@pages/Static';

export const HomeRouteName: Record<RoleEnum, RouterName> = {
  [RoleEnum.Admin]: RouterName.AdminTeachers,
  [RoleEnum.Marketer]: RouterName.AdminTeachers,
  [RoleEnum.Teacher]: RouterName.AdminCourses,
  [RoleEnum.User]: RouterName.Home,
};
export const getUserRole = (): Maybe<RoleEnum> => {
  const user = loadUser();

  if (user?.access_token) {
    const decodedToken = jwtDecode<UserType>(user.access_token);

    return decodedToken.role || null;
  }

  return null;
};

export const routeMiddleware = (
  roleAccess: RoleEnum[] | undefined,
): JSX.Element | void => {
  if (!roleAccess) {
    return;
  }
  const userRole = getUserRole();

  if (!userRole) {
    return <Redirect to={RouterName.Authorization} />;
  }
  if (roleAccess.map((el) => el.toLowerCase()).includes(userRole)) {
    return;
  }

  return <NotFoundView />;
};
