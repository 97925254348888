import React, { FC, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import { TeacherInput } from '@/apolloGenerated';
import { ActionButtons, ApolloErrorService, Container } from '@/shared';
import { RouterName } from '@app/router';
import {
  getTeacherInitState,
  TeacherForm,
  TeacherInputSteps,
  useCreateTeacher,
} from '@entities/Teacher';
import { LoaderOverlay, Spacer, toaster } from '@letsdance/ui-kit';
import { ActionButton } from '@shared/ui/ActionButtons/ActionButtons';
import { Head } from '@widgets/Layout';

export const TeacherCreateView: FC = () => {
  const { navigate } = useNavigate();
  const [createTeacher, { error, loading }] = useCreateTeacher();
  const [data, setData] = useState<TeacherInput>();

  const onClose = () => {
    navigate(RouterName.AdminTeachers);
  };
  const onCreate = async () => {
    if (!data) {
      return toaster.error({ title: 'Отсутствуют данные для обновления' });
    }
    createTeacher({
      input: data,
    });
  };

  const actions: ActionButton[] = [
    {
      handler: onClose,
      label: 'Отменить',
    },
    {
      handler: () => onCreate(),
      label: 'Создать',
    },
  ];

  return (
    <>
      <LoaderOverlay show={loading} />
      <Container slim>
        <Head title="Новый преподаватель" onBack={onClose} />
        <TeacherInputSteps />
      </Container>
      <Spacer size={8} />
      <ActionButtons actions={actions} />
      <Spacer size={20} />
      <Container slim>
        <TeacherForm
          initValues={getTeacherInitState() as any}
          onChange={setData}
          errors={
            ApolloErrorService.getFirstError(error?.graphQLErrors)?.extensions
              ?.validationErrors
          }
        />
      </Container>
    </>
  );
};
