import cx from 'classnames';

import { useModal } from '@/shared';
import {
  ArrowBottomIcon,
  ArrowRightIcon,
  Button,
  MinusIcon,
  Typography,
} from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface ListPickerProps<T> {
  initValues?: T[];
  valueKey: keyof T;
  labelKey: keyof T;
  disabled?: boolean;
  onChange(val: T[]): void;
  onEdit(val: T, readonly?: boolean): void;
  onAdd(): void;
  texts: {
    addBtn: string;
    removeModal: {
      title: string;
      description: string;
    };
  };
}
export const ListPicker = function <T>({
  disabled,
  initValues = [],
  labelKey,
  onAdd,
  onChange,
  onEdit,
  texts: { addBtn: addBtnText, removeModal: removeModalTexts },
  valueKey,
}: ListPickerProps<T>): JSX.Element {
  const openRemoveModal = useModal('listPickerRemove');

  const handleRemove = (key: string) => {
    openRemoveModal({
      description: removeModalTexts.description,
      onSubmit() {
        const newValues = initValues.filter((el) => el[valueKey] !== key);

        onChange(newValues);
      },
      title: removeModalTexts.title,
    });
  };

  return (
    <div className={styles.listPicker}>
      {initValues.map((el) => (
        <div key={el[valueKey] as string} className={styles.listPicker__item}>
          <div
            className={styles.listPicker__wrap}
            onClick={() => onEdit(el, disabled)}>
            <Typography
              variant="body-16"
              color="on-surface-primary-1"
              className={styles.listPicker__label}>
              {el[labelKey] as string}
            </Typography>
            <ArrowRightIcon color="var(--on-surface-primary-1)" />
          </div>
          <Button
            icon
            disabled={disabled}
            prependIcon={MinusIcon}
            variant="outlined"
            size="large"
            onClick={() => handleRemove(el[valueKey] as string)}
          />
        </div>
      ))}
      <div
        className={styles.listPicker__item}
        onClick={() => !disabled && onAdd()}>
        <div
          className={cx(
            styles.listPicker__wrap,
            disabled && styles.listPicker__wrap_disabled,
          )}>
          <Typography
            variant="body-16"
            color="on-surface-primary-1"
            className={styles.listPicker__label}>
            {addBtnText}
          </Typography>
          <ArrowBottomIcon color="var(--on-surface-primary-1)" />
        </div>
      </div>
    </div>
  );
};
