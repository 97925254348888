import { FC, PropsWithChildren } from 'react';

import { useMeQuery } from '@/apolloGenerated';
import { LoaderOverlay } from '@letsdance/ui-kit';
import { Base } from '@widgets/Layout/ui/Base/Base';

export const UserLayout: FC<PropsWithChildren> = ({ children }) => {
  const { data, loading } = useMeQuery();

  return (
    <Base user={data?.me}>
      {children}
      <LoaderOverlay show={loading} />
    </Base>
  );
};
