import React, { FC } from 'react';
import cx from 'classnames';
import moment from 'moment';

import {
  CheckDenseIcon,
  CopyDenseIcon,
  Typography,
  useCopyToClipboard,
} from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface ContactHeadInfoProps {
  signUpDate?: number;
  uuid?: string;
}
export const ContactHeadInfo: FC<ContactHeadInfoProps> = ({
  signUpDate,
  uuid,
}) => {
  const [copyValue, copy] = useCopyToClipboard();

  return (
    <div className={styles.contactHeadInfo}>
      <Typography variant="body-16" color="on-surface-primary-1">
        Зарегистрирован {moment(signUpDate).format('DD MMM YYYY')}
      </Typography>
      <Typography
        className={styles.contactHeadInfo__btm}
        variant="body-16"
        color="on-surface-primary-1">
        UUID:
        <Typography tag="span" variant="body-16" color="primary-1">
          {uuid}
        </Typography>
        {copyValue ? (
          <CheckDenseIcon className={styles.contactHeadInfo__icon} />
        ) : (
          <CopyDenseIcon
            className={cx(
              styles.contactHeadInfo__icon,
              styles.contactHeadInfo__icon_hovered,
            )}
            onClick={() => copy(uuid || '')}
          />
        )}
      </Typography>
    </div>
  );
};
