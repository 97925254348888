import { FC, PropsWithChildren } from 'react';
import cx from 'classnames';

import { PropsWithNativeTypes } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface ContainerProps {
  slim?: boolean;
}
export const Container: FC<
  PropsWithChildren<PropsWithNativeTypes<ContainerProps, HTMLDivElement>>
> = ({ children, className, slim, ...rest }) => (
  <div
    className={cx(styles.container, slim && styles.container_slim, className)}
    {...rest}>
    {children}
  </div>
);
