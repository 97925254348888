import { useEffect } from 'react';

import {
  Errors,
  UpdateCourseMutation,
  UpdateCourseMutationVariables,
  useUpdateCourseMutation,
} from '@/apolloGenerated';
import { ApolloErrorService } from '@/shared';
import { MutationResult } from '@apollo/client';
import { getCourseInput } from '@entities/Course/libs/hooks/getCourseInput';
import { toaster } from '@letsdance/ui-kit';

export const useUpdateCourse = (): [
  (vars: UpdateCourseMutationVariables) => Promise<void>,
  MutationResult<UpdateCourseMutation>,
] => {
  const [updateCourse, result] = useUpdateCourseMutation();

  const onUpdateCourse = async ({
    input,
    uuid,
  }: UpdateCourseMutationVariables) => {
    await updateCourse({
      variables: {
        input: getCourseInput(input),
        uuid,
      },
    });
  };

  useEffect(() => {
    if (result.data) {
      toaster.success({ title: 'Курс успешно обновлён' });
      result.client.cache.evict({
        args: { uuid: result.data.updateProduct.uuid },
        fieldName: 'product',
      });
      result.client.cache.evict({ fieldName: 'productsList' });
    }
    if (
      result.error &&
      ApolloErrorService.getFirstError(result.error?.graphQLErrors)?.message ===
        Errors.CanNotPublishProductWithoutTariff
    ) {
      toaster.error({
        title: 'Для публикации необходимо добавить хотя бы один тариф',
      });
    }
  }, [result.data, result.error]);

  return [onUpdateCourse, result];
};
