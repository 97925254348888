import { FC, useMemo, useState } from 'react';
import Decimal from 'decimal.js-light';

import {
  Maybe,
  OrdersListQuery,
  OrderSortKeys,
  useOrdersListQuery,
} from '@/apolloGenerated';
import {
  Container,
  formatAmount,
  formatSort,
  renderCellDate,
  renderCellItemValue,
  useTeacherId,
} from '@/shared';
import { router, RouterName } from '@app/router';
import {
  LoaderOverlay,
  SortType,
  TableController,
  Typography,
} from '@letsdance/ui-kit';

import cls from './styles.module.scss';

const headers = [
  { sortable: true, title: 'Дата', value: 'createdAt', width: 160 },
  { key: true, sortable: true, title: 'Операция', value: 'id', width: 160 },
  { title: 'Сведения', value: 'info' },
  { title: 'Контрагент', value: 'user' },
  { title: 'Оборот, ₽', value: 'turnover' },
  { title: 'Комиссия, ₽', value: 'commission' },
  { title: 'Прибыль, ₽ ', value: 'profit' },
];
const pageSize = 15;

export const ReceiptsTable: FC = () => {
  const teacherId = useTeacherId()!;
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<OrderSortKeys>(OrderSortKeys.CreatedAt);

  const {
    data: curData,
    loading,
    previousData,
  } = useOrdersListQuery({
    skip: !teacherId,
    variables: {
      filter: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
      },
      teacherId,
    },
  });

  const viewContact = (uuid: string) => {
    window.open(
      router.urlFor(RouterName.AdminContactEdit, {
        uuid,
      }),
      '_blank',
      'noopener,noreferrer',
    );
  };

  const data = curData || previousData;
  const rowTemplate = (receipt: OrdersListQuery['ordersList']['rows'][0]) => ({
    commission: formatAmount(receipt.teacherCommission),
    createdAt: renderCellDate({ time: true, timestamp: receipt.createdAt }),
    id: receipt.id,
    info: renderCellItemValue({
      label: receipt.product.name,
      value: receipt.tariff.name,
    }),
    profit: formatAmount(
      new Decimal(receipt.price).minus(receipt.teacherCommission).toNumber(),
    ),
    turnover: formatAmount(receipt.price),
    user: renderCellItemValue({
      label: (
        <Typography
          variant="body-14"
          color="on-surface-primary-1"
          rel="noreferrer"
          className={cls.btn}
          onClick={() => viewContact(receipt.user.uuid)}>
          {receipt.user.last_name} {receipt.user.first_name}
        </Typography>
      ),
      value: receipt.user.email,
    }),
  });
  const items = useMemo(
    () => (data?.ordersList.rows || []).map((item) => rowTemplate(item)),
    [data],
  );

  return (
    <Container className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        data={items}
        headers={headers}
        total={data?.ordersList.count}
        onChangePage={setPage}
        pageSize={pageSize}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as OrderSortKeys);
        }}
        notResetPage
      />
    </Container>
  );
};
