import { FC, useState } from 'react';

import { ListFilterWrapper, SearchTextField } from '@/shared';

export const ContactListFilter: FC = () => {
  const [search, setSearch] = useState('');

  return (
    <ListFilterWrapper>
      <SearchTextField
        name="search"
        placeholder="ID, фамилия, соцсети, почта"
        initValue={search}
        onChange={(e) => setSearch(e.target.value)}
      />
    </ListFilterWrapper>
  );
};
