import { FC } from 'react';

import { useFinanceStatsQuery } from '@/apolloGenerated';
import { Container, StatItem, useTeacherId } from '@/shared';
import { Divider } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface FinanceStatsProps {
  filterSlot?: JSX.Element;
}
export const FinanceStats: FC<FinanceStatsProps> = ({ filterSlot }) => {
  const teacherId = useTeacherId()!;
  const { data } = useFinanceStatsQuery({
    skip: !teacherId,
    variables: { teacherId },
  });
  const stats = data?.ordersStats;
  const balance = data?.teacher.balance || 0;
  const orderCount = stats?.ordersCounts?.all || 0;
  const commission = stats?.financeStats?.totalCommission || 0;
  const totalWithdrawals = stats?.financeStats?.totalWithdrawals || 0;
  const totalReceived = stats?.financeStats?.totalReceived || 0;
  const profit = stats?.financeStats?.profit || 0;

  return (
    <Container className={styles.financeStats}>
      <div className={styles.financeStats__totalBlock}>
        <StatItem
          label="Текущий баланс, ₽"
          value={balance}
          format
          fixed={false}
        />
        <Divider />
        <StatItem label="Покупок" value={orderCount} />
      </div>
      <div className={styles.financeStats__statsBlock}>
        {filterSlot && (
          <div className={styles.financeStats__filters}>{filterSlot}</div>
        )}
        <div className={styles.financeStats__statsWrap}>
          <StatItem
            label="Поступило, ₽"
            value={totalReceived}
            format
            fixed={false}
          />
          <Divider vertical />
          <StatItem
            label="В т. ч. комиссия, ₽"
            value={commission}
            format
            fixed={false}
          />
          <Divider vertical />
          <StatItem label="Прибыль, ₽" value={profit} format fixed={false} />
          <Divider vertical />
          <StatItem
            label="Выведено, ₽"
            value={totalWithdrawals}
            format
            fixed={false}
          />
        </div>
      </div>
    </Container>
  );
};
