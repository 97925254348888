import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';

import {
  PreviewProductInput,
  ProductInput,
  ProductType,
} from '@/apolloGenerated';
import {
  DeeplinkCopy,
  FormSection,
  handleNumberInput,
  useImageUploader,
} from '@/shared';
import { EventTariffsPicker } from '@entities/Tariff';
import {
  Checkbox,
  ImageUploader,
  Maybe,
  NestedKeys,
  RadioButtons,
  Spacer,
  Switch,
  TextField,
  Typography,
  useForm,
} from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface CourseFormProps {
  initValues: ProductType;
  onChange(val: ProductInput): void;
  errors?: Record<NestedKeys<ProductInput>, string[]>;
  readonly?: boolean;
}
export const CourseForm: FC<CourseFormProps> = ({
  errors,
  initValues,
  onChange,
  readonly,
}) => {
  const [isPeriod, setIsPeriod] = useState<boolean>(!initValues.period);
  const [dayPeriod, setDayPeriod] = useState<Maybe<number>>(
    initValues.period ? moment.duration(initValues.period).asDays() : null,
  );

  const uploadImage = useImageUploader();
  const {
    onChange: onChangeValue,
    setBatchValues,
    values,
  } = useForm<ProductInput>(console.log, {
    ...initValues,
    tariffsUuid: initValues.tariffs.map((el) => el.uuid),
  });

  useEffect(() => {
    onChange(values);
  }, [values]);

  const updatePreview = function <T extends keyof PreviewProductInput>(
    key: T,
    value: PreviewProductInput[T],
  ) {
    setBatchValues({
      preview: {
        ...values.preview,
        [key]: value,
      },
    });
  };

  useEffect(() => {
    setBatchValues({ period: dayPeriod ? `P${dayPeriod}DT0S` : null });
  }, [dayPeriod]);

  return (
    <div className={styles.eventForm}>
      <FormSection title="Основная информация">
        <div className={styles.eventForm__mainInfo}>
          <ImageUploader
            initValue={values.img}
            onChange={(val) => setBatchValues({ img: val })}
            disabled={readonly}
            error={errors?.img}
            uploadImageCallback={uploadImage}
          />
          <div>
            <TextField
              label="Название"
              size="large"
              disabled={readonly}
              fullWidth
              error={errors?.name}
              name="name"
              initValue={values.name}
              onChange={onChangeValue}
            />
            <Spacer size={10} />
            <TextField
              label="Ключ диплинка (латиница)"
              size="large"
              disabled={readonly}
              fullWidth
              error={errors?.query}
              name="query"
              initValue={values.query!}
              onChange={onChangeValue}
            />
            <Spacer size={4} />
            <DeeplinkCopy to="course" value={values.query} />
            <Spacer size={4} />
            <div className="grid-2">
              <TextField
                label="ID Telegram канала"
                size="large"
                disabled={readonly}
                fullWidth
                error={errors?.chanel_id}
                name="chanel_id"
                initValue={String(values.chanel_id || '')}
                onKeyPress={handleNumberInput({
                  allowDecimal: true,
                  allowNegative: true,
                })}
                onChange={(e) =>
                  setBatchValues({
                    chanel_id: e.target.value ? Number(e.target.value) : 0,
                  })
                }
              />
              <TextField
                label="ID Telegram чата"
                size="large"
                disabled={readonly}
                fullWidth
                error={errors?.chat_id}
                name="chat_id"
                initValue={String(values.chat_id || '')}
                onKeyPress={handleNumberInput({ allowNegative: true })}
                onChange={(e) =>
                  setBatchValues({
                    chat_id: e.target.value ? Number(e.target.value) : null,
                  })
                }
              />
            </div>
            <Spacer size={8} />
            <Typography variant="body-14" color="on-surface-primary-2">
              Есть возможность создать отдельный Telegram канал для каждого
              тарифа в его настройках.
            </Typography>
          </div>
        </div>
        <Spacer size={10} />
        <TextField
          tag="textarea"
          label="Описание"
          size="large"
          style={{ height: 200 }}
          disabled={readonly}
          fullWidth
          error={errors?.description}
          name="description"
          initValue={values.description}
          onChange={onChangeValue}
        />
      </FormSection>
      <FormSection title="Доступ к курсу для пользователей">
        <Checkbox
          checked={isPeriod}
          onChange={(e) => {
            setIsPeriod(e.target.checked);
            setDayPeriod(null);
          }}>
          Доступ к курсу не ограничен по времени.
        </Checkbox>
        <Spacer size={4} />
        <TextField
          label="Количество дней"
          size="large"
          style={{ width: 232 }}
          disabled={readonly || isPeriod}
          fullWidth
          error={errors?.period}
          name="period"
          onKeyPress={handleNumberInput()}
          value={String(dayPeriod || '')}
          onChange={(e) =>
            setDayPeriod(e.target.value ? +e.target.value : null)
          }
        />
      </FormSection>
      <FormSection title="Способ оплаты">
        <RadioButtons
          name="activeAcquiring"
          checked={values.activeAcquiring}
          label="Онлайн-платеж по реквизитам, указанным в профиле"
          onChange={onChangeValue}
          disabled={readonly}
        />
        <Spacer size={6} />
        <RadioButtons
          name="activeAcquiring"
          checked={!values.activeAcquiring}
          label="Оплата по инструкции"
          onChange={(e) => {
            setBatchValues({ activeAcquiring: !e.target.checked });
          }}
          disabled={readonly}
        />
        <Spacer size={6} />
        <TextField
          tag="textarea"
          label="Инструкция"
          size="large"
          style={{ height: 200 }}
          disabled={readonly || values.activeAcquiring}
          fullWidth
          error={errors?.manualBuyText}
          name="manualBuyText"
          initValue={values.manualBuyText!}
          onChange={onChangeValue}
        />
      </FormSection>
      <FormSection title="Превью">
        <Switch
          label="Активно"
          checked={values.preview.active}
          disabled={readonly}
          onChange={(e) => updatePreview('active', e.target.checked)}
        />
        {values.preview.active && (
          <>
            <Spacer size={10} />
            <div className={styles.eventForm__mainInfo}>
              <ImageUploader
                initValue={values.preview.url}
                error={errors?.['preview.url']}
                onChange={(val) => updatePreview('url', val)}
                disabled={readonly}
                uploadImageCallback={uploadImage}
              />
              <div>
                <TextField
                  label="Текст кнопки"
                  size="large"
                  disabled={readonly}
                  fullWidth
                  name="back_button_text"
                  error={errors?.['preview.back_button_text']}
                  initValue={values.preview.back_button_text!}
                  onChange={(e) =>
                    updatePreview('back_button_text', e.target.value)
                  }
                />
                <Spacer size={10} />
                <TextField
                  tag="textarea"
                  label="Описание"
                  size="large"
                  style={{ height: 190 }}
                  disabled={readonly}
                  fullWidth
                  name="description"
                  error={errors?.['preview.description']}
                  initValue={values.preview.description!}
                  onChange={(e) => updatePreview('description', e.target.value)}
                />
              </div>
            </div>
          </>
        )}
      </FormSection>
      <FormSection title="Тарифы">
        <EventTariffsPicker
          initValue={initValues.tariffs}
          disabled={readonly}
          onChange={(tariffsUuid) => setBatchValues({ tariffsUuid })}
        />
      </FormSection>
      <FormSection title="Тексты">
        <TextField
          tag="textarea"
          label="FAQ"
          size="large"
          style={{ height: 200 }}
          disabled={readonly}
          fullWidth
          error={errors?.faq}
          name="faq"
          initValue={values.faq!}
          onChange={onChangeValue}
        />
        <Spacer size={10} />
        <TextField
          tag="textarea"
          label="Текст успешной покупки"
          size="large"
          style={{ height: 200 }}
          disabled={readonly}
          fullWidth
          error={errors?.successBuyText}
          name="successBuyText"
          initValue={values.successBuyText!}
          onChange={onChangeValue}
        />
      </FormSection>
    </div>
  );
};
