import { gql } from '@apollo/client';

export const orderFragment = gql`
  fragment OrderFields on OrderType {
    createdAt
    updatedAt
    deletedAt
    id
    completed
    expired_date
    price
    teacherCommission
    status
    invoice_url
  }
`;
export const ordersList = gql`
  ${orderFragment}
  query ordersList(
    $filter: OrdersListFilterInput
    $teacherId: Int!
    $uuidUser: UUID
    $uuidProduct: UUID
    $isExpired: Boolean
  ) {
    ordersList(
      filters: $filter
      args: {
        teacherId: $teacherId
        uuidUser: $uuidUser
        uuidProduct: $uuidProduct
      }
      params: { status: Success, completed: $isExpired }
    ) {
      count
      rows {
        ...OrderFields
        user {
          first_name
          last_name
          email
          username
          uuid
          phone
        }
        product {
          name
          type
        }
        tariff {
          name
        }
      }
    }
  }
`;

export const ordersCount = gql`
  query ordersCount(
    $filter: OrdersListFilterInput
    $teacherId: Int!
    $uuidUser: UUID
    $uuidProduct: UUID
    $isExpired: Boolean
  ) {
    ordersList(
      filters: $filter
      args: {
        teacherId: $teacherId
        uuidUser: $uuidUser
        uuidProduct: $uuidProduct
      }
      params: { status: Success, completed: $isExpired }
    ) {
      count
    }
  }
`;
