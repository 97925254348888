import React, { FC, MouseEvent, useState } from 'react';

import { useModal } from '@/shared';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownList,
  MoreIcon,
  stopEvent,
} from '@letsdance/ui-kit';

interface UserTeacherControlProps {
  uuid: string;
}

export const UserTeacherControl: FC<UserTeacherControlProps> = ({ uuid }) => {
  const openDeactivateModal = useModal('deactivateUserTeacher');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleChangeDropdown = (val: boolean) => {
    setIsOpen(val);
  };

  const eventCallback = (callback: () => void) => (e: MouseEvent) => {
    setIsOpen(false);
    stopEvent(e);
    callback();
  };

  const handleRemove = () => {
    openDeactivateModal({ uuid });
  };

  return (
    <Dropdown
      value={isOpen}
      targetSlot={
        <Button
          icon
          prependIcon={MoreIcon}
          variant="text"
          size="large"
          onClick={stopEvent}
        />
      }
      placement="bottom-end"
      width={260}
      zIndex={1000}
      onChange={handleChangeDropdown}>
      <DropdownList maxHeight={100}>
        <DropdownItem
          fullWidth
          onClick={eventCallback(handleRemove)}
          label="Деактивировать"
        />
      </DropdownList>
    </Dropdown>
  );
};
