import { FC, useState } from 'react';

import { ListFilterWrapper, SearchTextField } from '@/shared';

export const CourseListFilter: FC = () => {
  const [search, setSearch] = useState('');

  return (
    <ListFilterWrapper>
      <SearchTextField
        name="search"
        placeholder="Название, стиль"
        initValue={search}
        onChange={(e) => setSearch(e.target.value)}
      />
    </ListFilterWrapper>
  );
};
