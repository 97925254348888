import { FC, PropsWithChildren } from 'react';

import { PropsWithNativeTypes, Spacer, Typography } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface FormSectionProps {
  title?: string;
  offBtmSpacing?: boolean;
  classNameContent?: string;
  rightSlot?: JSX.Element;
}
export const FormSection: FC<
  PropsWithChildren<PropsWithNativeTypes<FormSectionProps, HTMLDivElement>>
> = ({
  children,
  classNameContent,
  offBtmSpacing,
  rightSlot,
  title,
  ...rest
}) => (
  <section {...rest}>
    {title && (
      <>
        <div className={styles.formSection__head}>
          <Typography variant="head-24" color="on-surface-primary-1">
            {title}
          </Typography>
          {rightSlot}
        </div>
        <Spacer size={12} />
      </>
    )}
    <div className={classNameContent}>{children}</div>
    {!offBtmSpacing && <Spacer size={20} />}
  </section>
);
