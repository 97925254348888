import { Route } from 'react-justanother-router';

import { RoleEnum } from '@/apolloGenerated';
import { RouterName } from '@app/router';
import * as views from '@pages/Admin';
import { AuthLayout, UserLayout } from '@widgets/Layout';

export const adminRoutes: Route[] = [
  {
    component: views.AuthView,
    name: RouterName.Authorization,
    path: '/login',
    props: {
      layout: AuthLayout,
    },
  },
  {
    children: [
      {
        component: views.CourseEditView,
        name: RouterName.AdminCourseEdit,
        path: '/edit/:uuid',
        props: {
          accessRole: [RoleEnum.Teacher],
          layout: UserLayout,
        },
      },
      {
        component: views.CourseCreateView,
        name: RouterName.AdminCourseCreate,
        path: '/create',
        props: {
          accessRole: [RoleEnum.Teacher],
          layout: UserLayout,
        },
      },
    ],
    component: views.CoursesView,
    name: RouterName.AdminCourses,
    path: '/courses',
    props: {
      accessRole: [RoleEnum.Teacher],
      layout: UserLayout,
    },
  },
  {
    children: [
      {
        component: views.ContactEditView,
        name: RouterName.AdminContactEdit,
        path: '/edit/:uuid',
        props: {
          accessRole: [RoleEnum.Teacher],
          layout: UserLayout,
        },
      },
    ],
    component: views.ContactView,
    name: RouterName.AdminContact,
    path: '/contacts',
    props: {
      accessRole: [RoleEnum.Teacher],
      layout: UserLayout,
    },
  },
  {
    component: views.PurchasesView,
    name: RouterName.AdminPurchases,
    path: '/purchases',
    props: {
      accessRole: [RoleEnum.Teacher],
      layout: UserLayout,
    },
  },
  {
    children: [
      {
        component: views.CreateWithdrawView,
        name: RouterName.AdminWithdrawCreate,
        path: '/withdraw',
        props: {
          accessRole: [RoleEnum.Teacher],
          layout: UserLayout,
        },
      },
    ],
    component: views.FinanceView,
    name: RouterName.AdminFinance,
    path: '/finances',
    props: {
      accessRole: [RoleEnum.Teacher],
      layout: UserLayout,
    },
  },
  {
    component: views.ProfileTeacherView,
    name: RouterName.AdminProfile,
    path: '/profile',
    props: {
      accessRole: [RoleEnum.Teacher],
      layout: UserLayout,
    },
  },
  {
    children: [
      {
        component: views.TeacherEditView,
        name: RouterName.AdminTeacherEdit,
        path: '/edit/:id',
        props: {
          accessRole: [RoleEnum.Admin, RoleEnum.Marketer],
          layout: UserLayout,
        },
      },
      {
        component: views.TeacherCreateView,
        name: RouterName.AdminTeacherCreate,
        path: '/create',
        props: {
          accessRole: [RoleEnum.Admin, RoleEnum.Marketer],
          layout: UserLayout,
        },
      },
    ],
    component: views.TeachersView,
    name: RouterName.AdminTeachers,
    path: '/teachers',
    props: {
      accessRole: [RoleEnum.Admin, RoleEnum.Marketer],
      layout: UserLayout,
    },
  },
];
