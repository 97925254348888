import React, { FC, useState } from 'react';

import { useTeachersListQuery } from '@/apolloGenerated';
import { Container } from '@/shared';
import { TeachersTable } from '@features/Teacher';
import { Divider, Spacer, TabItem, Tabs } from '@letsdance/ui-kit';

export enum TeachersTabs {
  All,
  Verified,
}
export const TeachersList: FC = () => {
  const { data: teachersVerified } = useTeachersListQuery({
    variables: {
      filter: { pagination: { page: 1, pageSize: 1 } },
      verified: true,
    },
  });
  const { data: teachers } = useTeachersListQuery({
    variables: {
      filter: { pagination: { page: 1, pageSize: 1 } },
    },
  });
  const allCount = teachers?.teachersList.count || 0;
  const verifiedCount = teachersVerified?.teachersList.count || 0;
  const [tab, setTab] = useState<TeachersTabs>(TeachersTabs.All);

  return (
    <div>
      <Container>
        <Tabs initValue={tab} onChange={setTab}>
          <TabItem value={TeachersTabs.All} label={`Все (${allCount})`} />
          <TabItem
            value={TeachersTabs.Verified}
            label={`Верифицированные (${verifiedCount})`}
          />
        </Tabs>
      </Container>
      <Divider />
      <Spacer size={12} />
      <Container>
        <TeachersTable isVerified={tab === TeachersTabs.Verified} />
      </Container>
    </div>
  );
};
